const SquaresBg = () => {
  return (
    <svg
      width="768"
      height="768"
      viewBox="0 0 768 768"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Background pattern decorative">
        <mask
          id="mask0_9812_13142"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="768"
          height="768"
        >
          <g id="Mask">
            <g id="_Background mask">
              <rect
                width="768"
                height="768"
                fill="url(#paint0_radial_9812_13142)"
              />
            </g>
          </g>
        </mask>
        <g mask="url(#mask0_9812_13142)">
          <g id="Content">
            <g id="Lines" clipPath="url(#clip0_9812_13142)">
              <g id="Vertical">
                <g clipPath="url(#clip1_9812_13142)">
                  <line id="Line" x1="0.5" x2="0.5" y2="768" stroke="#E9EAEB" />
                  <line
                    id="Line_2"
                    x1="48.5"
                    x2="48.5"
                    y2="768"
                    stroke="#E9EAEB"
                  />
                  <line
                    id="Line_3"
                    x1="96.5"
                    x2="96.5"
                    y2="768"
                    stroke="#E9EAEB"
                  />
                  <line
                    id="Line_4"
                    x1="144.5"
                    x2="144.5"
                    y2="768"
                    stroke="#E9EAEB"
                  />
                  <line
                    id="Line_5"
                    x1="192.5"
                    x2="192.5"
                    y2="768"
                    stroke="#E9EAEB"
                  />
                  <line
                    id="Line_6"
                    x1="240.5"
                    x2="240.5"
                    y2="768"
                    stroke="#E9EAEB"
                  />
                  <line
                    id="Line_7"
                    x1="288.5"
                    x2="288.5"
                    y2="768"
                    stroke="#E9EAEB"
                  />
                  <line
                    id="Line_8"
                    x1="336.5"
                    x2="336.5"
                    y2="768"
                    stroke="#E9EAEB"
                  />
                  <line
                    id="Line_9"
                    x1="384.5"
                    x2="384.5"
                    y2="768"
                    stroke="#E9EAEB"
                  />
                  <line
                    id="Line_10"
                    x1="432.5"
                    x2="432.5"
                    y2="768"
                    stroke="#E9EAEB"
                  />
                  <line
                    id="Line_11"
                    x1="480.5"
                    x2="480.5"
                    y2="768"
                    stroke="#E9EAEB"
                  />
                  <line
                    id="Line_12"
                    x1="528.5"
                    x2="528.5"
                    y2="768"
                    stroke="#E9EAEB"
                  />
                  <line
                    id="Line_13"
                    x1="576.5"
                    x2="576.5"
                    y2="768"
                    stroke="#E9EAEB"
                  />
                  <line
                    id="Line_14"
                    x1="624.5"
                    x2="624.5"
                    y2="768"
                    stroke="#E9EAEB"
                  />
                  <line
                    id="Line_15"
                    x1="672.5"
                    x2="672.5"
                    y2="768"
                    stroke="#E9EAEB"
                  />
                  <line
                    id="Line_16"
                    x1="720.5"
                    x2="720.5"
                    y2="768"
                    stroke="#E9EAEB"
                  />
                </g>
                <rect
                  x="0.5"
                  y="0.5"
                  width="767"
                  height="767"
                  stroke="#E9EAEB"
                />
              </g>
              <g id="Horizontal">
                <g clipPath="url(#clip2_9812_13142)">
                  <line
                    id="Line_17"
                    y1="47.5"
                    x2="768"
                    y2="47.5"
                    stroke="#E9EAEB"
                  />
                  <line
                    id="Line_18"
                    y1="95.5"
                    x2="768"
                    y2="95.5"
                    stroke="#E9EAEB"
                  />
                  <line
                    id="Line_19"
                    y1="143.5"
                    x2="768"
                    y2="143.5"
                    stroke="#E9EAEB"
                  />
                  <line
                    id="Line_20"
                    y1="191.5"
                    x2="768"
                    y2="191.5"
                    stroke="#E9EAEB"
                  />
                  <line
                    id="Line_21"
                    y1="239.5"
                    x2="768"
                    y2="239.5"
                    stroke="#E9EAEB"
                  />
                  <line
                    id="Line_22"
                    y1="287.5"
                    x2="768"
                    y2="287.5"
                    stroke="#E9EAEB"
                  />
                  <line
                    id="Line_23"
                    y1="335.5"
                    x2="768"
                    y2="335.5"
                    stroke="#E9EAEB"
                  />
                  <line
                    id="Line_24"
                    y1="383.5"
                    x2="768"
                    y2="383.5"
                    stroke="#E9EAEB"
                  />
                  <line
                    id="Line_25"
                    y1="431.5"
                    x2="768"
                    y2="431.5"
                    stroke="#E9EAEB"
                  />
                  <line
                    id="Line_26"
                    y1="479.5"
                    x2="768"
                    y2="479.5"
                    stroke="#E9EAEB"
                  />
                  <line
                    id="Line_27"
                    y1="527.5"
                    x2="768"
                    y2="527.5"
                    stroke="#E9EAEB"
                  />
                  <line
                    id="Line_28"
                    y1="575.5"
                    x2="768"
                    y2="575.5"
                    stroke="#E9EAEB"
                  />
                  <line
                    id="Line_29"
                    y1="623.5"
                    x2="768"
                    y2="623.5"
                    stroke="#E9EAEB"
                  />
                  <line
                    id="Line_30"
                    y1="671.5"
                    x2="768"
                    y2="671.5"
                    stroke="#E9EAEB"
                  />
                  <line
                    id="Line_31"
                    y1="719.5"
                    x2="768"
                    y2="719.5"
                    stroke="#E9EAEB"
                  />
                  <line
                    id="Line_32"
                    y1="767.5"
                    x2="768"
                    y2="767.5"
                    stroke="#E9EAEB"
                  />
                </g>
                <rect
                  x="0.5"
                  y="0.5"
                  width="767"
                  height="767"
                  stroke="#E9EAEB"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
      <defs>
        <radialGradient
          id="paint0_radial_9812_13142"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(384 384) rotate(90) scale(384 384)"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </radialGradient>
        <clipPath id="clip0_9812_13142">
          <rect width="768" height="768" fill="white" />
        </clipPath>
        <clipPath id="clip1_9812_13142">
          <rect width="768" height="768" fill="white" />
        </clipPath>
        <clipPath id="clip2_9812_13142">
          <rect width="768" height="768" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquaresBg;
