import { DateTime } from "luxon";

export function humanize(str) {
  return str.replace(/_/g, " ").replace(/\b\w/g, c => c.toUpperCase());
}

export const formatCompactTime = utcDateString => {
  const now = DateTime.local();
  const parsedDate = DateTime.fromISO(utcDateString, { zone: "utc" });
  const diff = now.diff(parsedDate, [
    "minutes",
    "hours",
    "days",
    "weeks",
    "months",
  ]);

  const timeUnits = [
    { value: diff.as("months"), unit: "month", short: "month" },
    { value: diff.as("weeks"), unit: "week", short: "week" },
    { value: diff.as("days"), unit: "day", short: "day" },
    { value: diff.as("hours"), unit: "hour", short: "hour" },
    { value: diff.as("minutes"), unit: "minute", short: "min" },
  ];

  for (const { value, short } of timeUnits) {
    const flooredValue = Math.floor(value);
    if (flooredValue >= 1)
      return `${flooredValue} ${short}${flooredValue > 1 ? "s" : ""} ago`;
  }

  return "just now";
};

export const formatDate = inputDate => {
  // Create a Date object from the input string
  const date = new Date(inputDate);

  // Get the day of the week
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const dayOfWeek = daysOfWeek[date.getDay()];

  // Format the time (11:40 am format)
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? " PM" : " AM";
  const formattedTime = `${hours % 12 || 12}:${minutes.toString().padStart(2, "0")}${ampm}`;

  // Return the formatted string
  return `${dayOfWeek} ${formattedTime}`;
};

export const fixUnixTime = unixTime => {
  if (!unixTime) return unixTime;
  const timestamp = unixTime * 1000;
  const date = new Date(timestamp);

  return date.toLocaleString();
};

export const unixTimeToDate = (unixTime) => {
  if (!unixTime) return unixTime;
  const timestamp = unixTime * 1000;
  const date = new Date(timestamp);
  return date.toLocaleString("en-US", { month: "short", year: "numeric" });
}
