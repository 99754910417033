import React, { useState, useEffect, useCallback } from "react";

import DeployOptions from "./Deploy/DeployOptions.jsx";
import BasicDeploy from "./Deploy/BasicDeploy.jsx";
import IntermediateDeploy from "./Deploy/IntermediateDeploy.jsx";
import AdvancedDeploy from "./Deploy/AdvancedDeploy.jsx";
import OneClickInstallations from "./Deploy/OneClickInstallations.jsx";
import CenterWrapper from "../../components/CenterWrapper.jsx";
import GeneralSetupTab from "../../components/GeneralSetupTab";
import DeployDomainsAccordion from "./Deploy/DeployDomainsAccordion.jsx";

import { useCompanyApi } from "../../hooks/useCompanyApi.js";
import api from "../../api.js";

import GlobalLoadingSpinner from "../../components/GlobalSpinner.jsx";

const Deploy = () => {
  const [deployType, setDeployType] = useState("Basic");

  const [domains, setDomains] = useState([]);
  const [loadingDomains, setLoadingDomains] = useState(true);
  const chatbotUuid = localStorage.getItem("chatbot_uuid") || "";

  const [companyName, setCompanyName] = useState("loading...");
  const [companyLogo, setCompanyLogo] = useState("loading...");
  const [secondaryColor, setSecondaryColor] = useState("loading...");
  const [fontColor, setFontColor] = useState("loading...");

  const { company, fetchCompany, loading: companyLoading } = useCompanyApi();

  const [loading, setLoading] = useState(false);

  const fetchSettings = async () => {
    try {
      const response = await api.get(`/api/chatbot/${chatbotUuid}`);
      const chatbot = response.data;

      setSecondaryColor(`#${chatbot.button_color || "#7856d2"}`);
      setFontColor(`#${chatbot.chat_color || "#7856d2"}`);
    } catch (err) {
      console.error("Failed to fetch settings", err);
    }
  };

  const fetchDomains = useCallback(async () => {
    const chatbotUuid = localStorage.getItem("chatbot_uuid");
    if (!chatbotUuid) {
      console.error("Chatbot UUID not found");
      return;
    }
    try {
      setLoadingDomains(true);
      const { data } = await api.get(`/api/widget-domains/${chatbotUuid}`);
      setDomains(data);
    } catch (error) {
      console.error("Error fetching domains:", error);
    } finally {
      setLoadingDomains(false);
    }
  }, []);

  const saveDomains = async newDomains => {
    const chatbotUuid = localStorage.getItem("chatbot_uuid");
    if (!chatbotUuid) {
      console.error("Chatbot UUID not found");
      return;
    }
    try {
      setLoading(true);
      await api.post(`/api/widget-domains/${chatbotUuid}`, {
        domains: newDomains,
      });
      setDomains(newDomains);
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!chatbotUuid) {
      console.error(
        "Chatbot UUID is not found in local storage or URL parameters"
      );
      return;
    }

    fetchSettings();
    fetchCompany();
  }, [chatbotUuid]);

  useEffect(() => {
    if (company) {
      setCompanyName(company.name);
      setCompanyLogo(company.picture_url);
    }
  }, [company]);

  useEffect(() => {
    fetchDomains();
  }, [fetchDomains]);

  return (
    <CenterWrapper>
      <GlobalLoadingSpinner loading={loading || companyLoading} />

      <GeneralSetupTab
        title={"Deploy"}
        subtitle={
          "Use this to deploy your new AI agent to your communication channels. Livechat and email are currently supported and more channels such as SMS, WhatsApp, Facebook Messenger and Instagram Messenger are coming soon."
        }
        pageType={"setup"}
        activeTab={"deploy"}
      />

      <DeployDomainsAccordion
        domains={domains}
        loadingDomains={loadingDomains}
        saveDomains={saveDomains}
      />

      <DeployOptions
        initialSelection={deployType}
        externalHandleSelection={setDeployType}
      />

      {deployType === "Basic" && (
        <BasicDeploy
          tenantId={chatbotUuid}
          secondaryColor={secondaryColor}
          fontColor={fontColor}
          companyName={companyName}
          companyLogo={companyLogo}
        />
      )}

      {deployType === "Intermediate" && (
        <IntermediateDeploy
          tenantId={chatbotUuid}
          secondaryColor={secondaryColor}
          fontColor={fontColor}
          companyName={companyName}
          companyLogo={companyLogo}
        />
      )}

      {deployType === "Advanced" && (
        <AdvancedDeploy
          tenantId={chatbotUuid}
          secondaryColor={secondaryColor}
          fontColor={fontColor}
          companyName={companyName}
          companyLogo={companyLogo}
        />
      )}

      <div className="border-t mt-12"></div>

      <section className="mt-12 mb-6">
        <h2 className="text-lg font-semibold mb-0">One-Click Installations</h2>
        <p className="text-gray-600 text-sm">
          Quickly add the chat widget to your website
        </p>

        <OneClickInstallations />
      </section>
    </CenterWrapper>
  );
};

export default Deploy;
