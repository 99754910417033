import SquaresBg from "../icons/SquaresBg";

const RecoveryPasswordWrapper = ({ children }) => {
  return (
    <div className="flex justify-center">
      <div className="fixed top-[-25%]">
        <SquaresBg />
      </div>
      <div className="mt-24 px-4 z-10 sm:max-w-[360px] w-full">{children}</div>
    </div>
  );
};

export default RecoveryPasswordWrapper;
