import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import api from "../api";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const CheckoutPage = ({planId, cost, option, setLoading}) => {
  const handleCheckout = async () => {
    const stripe = await stripePromise;
    setLoading(true);
    const response = await api.post("api/company/create_checkout_session", {plan_price_id: planId});
    const session = await response.data;
    if (session.id) {
      await stripe.redirectToCheckout({ sessionId: session.id });
    } else {
      console.error("Error creating checkout session:", session.error);
    }
    setLoading(false);
  };

  return (
    <div>
      <button onClick={handleCheckout}
        className="px-4 py-2 bg-purple-600 rounded-lg hover:bg-purple-700 transition duration-200 rounded text-white"
      >
        {option}
      </button>
    </div>
  );
};

export default CheckoutPage;
