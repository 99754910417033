import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { supabase } from "./supabaseClient";
import api from "./api";
import GlobalLoadingSpinner from "./components/GlobalSpinner";

const Callback = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isSignup =
    new URLSearchParams(location.search).get("signup") === "true";

  useEffect(() => {
    const handleOAuthCallback = async () => {
      try {
        const {
          data: { session },
          error,
        } = await supabase.auth.getSession();

        if (error) {
          console.error("Error getting session:", error);
          return;
        }

        const user = session?.user;
        if (user) {
          try {
            const getUserResponse = await api.get("/api/user");

            if (getUserResponse.status === 200) {
              const updateResponse = await api.put("/api/user", {
                last_login_at: new Date().toISOString(),
                supabase_id: user.id,
                email: user.email,
              });

              if (updateResponse.status === 200) {
                const { company_id, chatbot_uuid } = updateResponse.data;
                sessionStorage.setItem("company_id", company_id);
                localStorage.setItem("chatbot_uuid", chatbot_uuid);

                if (isSignup) {
                  navigate("/onboarding/use-case");
                } else {
                  navigate("/dashboard");
                }
              } else {
                console.error("Failed to update user:", updateResponse.data);
              }
            }
          } catch (getUserError) {
            if (getUserError.response && getUserError.response.status === 404) {
              try {
                const createResponse = await api.post("/api/create-user", {
                  email: user.email,
                  first_name: user.user_metadata?.first_name || null,
                  last_name: user.user_metadata?.last_name || null,
                });

                if (createResponse.status === 200) {
                  const { company_id, chatbot_uuid } = createResponse.data;
                  sessionStorage.setItem("company_id", company_id);
                  localStorage.setItem("chatbot_uuid", chatbot_uuid);
                  navigate("/onboarding/use-case");
                } else {
                  console.error(
                    "Failed to create new user:",
                    createResponse.data
                  );
                }
              } catch (createError) {
                console.error("Error creating new user:", createError);
              }
            } else {
              console.error("Error fetching user data:", getUserError);
            }
          }
        } else {
          console.error("User session is missing after Google sign-up");
        }
      } catch (callbackError) {
        console.error(
          "Unexpected error during callback handling:",
          callbackError
        );
      }
    };

    handleOAuthCallback();
  }, [navigate]);

  return <GlobalLoadingSpinner loading={true} />;
};

export default Callback;
