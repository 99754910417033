import { CloudArrowUpIcon } from "@heroicons/react/24/outline";
import { ReactComponent as LogoUploadAvatar } from "../../../imgs/icons/logoUploadAvatar.svg";
import React, { useState, useRef, useEffect } from "react";

const FileUpload = ({
  subtitle,
  currentValue,
  allowedExtensions = ["image/png", "image/jpeg", "image/gif", "image/svg+xml"],
  maxFileSizeMB = 2,
  maxWidth = 800,
  maxHeight = 400,
  onUpload,
}) => {
  const fileInputRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);

  const [preview, setPreview] = useState(undefined);

  useEffect(() => {
    setPreview(currentValue);
  }, [currentValue]);

  const validateFile = file => {
    if (!allowedExtensions.includes(file.type)) {
      alert("Invalid file type. Allowed: " + allowedExtensions.join(", "));
      return false;
    }

    if (file.size > maxFileSizeMB * 1024 * 1024) {
      alert(`File is too large. Max size: ${maxFileSizeMB}MB`);
      return false;
    }

    return new Promise(resolve => {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        if (img.width > maxWidth || img.height > maxHeight) {
          alert(`Image dimensions too large. Max: ${maxWidth}x${maxHeight}px`);
          resolve(false);
        } else {
          resolve(true);
        }
      };
    });
  };

  const handleFile = async file => {
    if (await validateFile(file)) {
      setPreview(URL.createObjectURL(file));
      onUpload(file);
    }
  };

  const handleChange = event => {
    const file = event.target.files[0];
    if (file) handleFile(file);
  };

  const handleDrop = async event => {
    event.preventDefault();
    setIsDragging(false);

    const file = event.dataTransfer.files[0];
    if (file) handleFile(file);
  };

  return (
    <div>
      <input
        type="file"
        accept={allowedExtensions.join(",")}
        onChange={handleChange}
        className="hidden"
        ref={fileInputRef}
      />

      <div className="flex items-center space-x-4">
        <div className="w-24 h-24 flex items-center justify-center">
          {preview ? (
            <img
              className="w-24 h-24 rounded-full object-cover"
              src={preview}
              alt="Uploaded Preview"
            />
          ) : (
            <LogoUploadAvatar className="h-12 w-12 text-gray-400" />
          )}
        </div>

        <div
          className={`flex-1 border border-gray-200 rounded-lg p-4 text-center ${isDragging ? "border-primary border-2 bg-gray" : ""}`}
          onClick={() => fileInputRef.current?.click()}
          onDragOver={e => {
            e.preventDefault();
            setIsDragging(true);
          }}
          onDrop={handleDrop}
          onDragLeave={() => setIsDragging(false)}
        >
          <div className="rounded-lg p-4 text-center">
            <div>
              <CloudArrowUpIcon
                aria-hidden="true"
                className="h-8 w-8 mx-auto text-gray-700 mb-2 p-1 m-1 rounded border shadow"
              />
            </div>
            <button
              type="button"
              className="text-primary font-medium text-sm hover:underline"
              htmlFor="logo"
            >
              Click to upload
            </button>
            <span className="text-sm text-gray-500"> or drag and drop</span>
            <p className="mt-2 text-xs text-gray-400">{subtitle}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FileUpload;
