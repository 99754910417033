import React from "react";
import { RocketLaunchIcon } from "@heroicons/react/24/outline";

const IntegrationBox = ({ integration, handleCardClick }) => {
  return (
    <div
      className="flex flex-col justify-between border border-gray-200 rounded-xl shadow-md bg-white"
      id="main-div"
    >
      {/* Header Section */}
      <div className="flex items-center px-6 pt-6 pb-4">
        <div className="w-12 h-12 mr-3 rounded-lg border-2 border-gray-300 overflow-hidden flex items-center justify-center">
          <img
            className="w-full h-full object-cover"
            src={integration.icon}
            alt={`${integration.name} logo`}
          />
        </div>
        <h3 className="text-lg mt-2 font-semibold text-gray-800">
          {integration.name}
        </h3>
      </div>

      {/* Description */}
      <p className="mt-2 px-6 text-sm text-gray-600">{integration.description}</p>

      {/* Footer Section */}
      <div className="flex p-4 items-center justify-end border-t mt-2 text-sm text-gray-500">
        {integration.status === "connected" ? (
          <span className="bg-green-100 text-green-700 px-3 py-1 rounded-full text-sm">
            Connected
          </span>
        ) : integration.comingSoon ? (
          <div className="flex items-center">
            <RocketLaunchIcon aria-hidden="true" className="size-6 mr-2" />
            <span className="font-semibold">Coming Soon</span>
          </div>
        ) : (
          <button
            className="px-4 py-2 rounded-md text-white bg-blue-500 hover:bg-blue-600 transition duration-300"
            onClick={() => handleCardClick(integration)}
          >
            Connect
          </button>
        )}
      </div>
    </div>
  );
};

export default IntegrationBox;
