import React from "react";
import ReactMarkdown from "react-markdown";
import { PaperClipIcon } from "@heroicons/react/24/outline";
import GenericAvatar from "./GenericAvatar";

const ThreadMessages = ({
  messages,
  avatar,
  firstName = "Unknown",
  lastName = "User",
}) => {
  const senderTitle = msg => {
    if (msg.sender === "user") return `${firstName} ${lastName}`;
    if (msg.you) return "You";
    return msg.authorName;
  };

  return (
    <>
      {messages.map((message, index) => (
        <div
          key={index}
          className={`flex my-5 ${
            message.sender === "user" ? "justifystart" : "justify-end"
          }`}
        >
          {message.sender === "user" && (
            <div className="mr-3">
              {avatar ? (
                <div>
                  <div className="min-w-8 w-8 h-8 overflow-hidden rounded-full border-2 border-gray-300">
                    <img
                      src={avatar}
                      alt="User Avatar"
                      className="w-full h-full object-cover"
                    />
                  </div>
                </div>
              ) : (
                <GenericAvatar firstName={firstName} lastName={lastName} />
              )}
            </div>
          )}
          {message.sender !== "user" && (
            <div className="mr-3">
              {message.avatar && message.avatar.length > 2 ? (
                <div>
                  <div className="min-w-8 w-10 h-10 overflow-hidden rounded-full">
                    <img
                      src={message.avatar}
                      alt="User Avatar"
                      className="w-full h-full object-cover"
                    />
                  </div>
                </div>
              ) : (
                <GenericAvatar
                  firstName={message.avatar[0]}
                  lastName={message.avatar[1]}
                />
              )}
            </div>
          )}
          <div
            className={`flex flex-col ${message.sender === "user" ? "w-[75%]" : "w-[55%]"}`}
          >
            <div className="flex justify-between items-center">
              <span className="text-sm font-semibold text-gray-800">
                {senderTitle(message)}
              </span>
              <span className="text-xs text-gray-500">{message.sentTime}</span>
            </div>

            {message.attachment ? (
              <div
                className={`w-full mt-1 pt-3 px-4 rounded-lg ${
                  message.sender === "user"
                    ? "bg-gray-50  border border-gray-200 rounded-tl-none"
                    : `rounded-tr-none text-white ${message.sender === "admin" ? "bg-blue-500" : "bg-secondary"}`
                }`}
              >
                <a
                  href={message.attachmentLink}
                  target="_blank"
                  className="flex flex-justify mb-3 underline"
                  rel="noopener noreferrer"
                >
                  <PaperClipIcon className="h-4 min-h-4 w-4 min-w-4 mr-1" />
                  <span className="text-ellipsis overflow-hidden">
                    {message.attachmentName}
                  </span>
                </a>
              </div>
            ) : (
              <div
                className={`mt-1 pt-3 px-4 rounded-lg ${
                  message.sender === "user"
                    ? "bg-gray-50 border border-gray-200 rounded-tl-none"
                    : `rounded-tr-none ${message.sender === "admin" ? "bg-blue-500" : "bg-secondary"}`
                }`}
              >
                <div className={`markdown-content ${message.sender !== "user" ? "text-white" : "text-gray-800"}`}>
                  <ReactMarkdown
                    components={{
                      a: ({ node, children, ...props }) => (
                        <a
                          {...props}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="inline-flex items-center gap-1 text-blue-500 hover:text-blue-600 underline underline-offset-2"
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244" />
                          </svg>
                          {children}
                        </a>
                      ),
                      p: ({ node, children, ...props }) => {
                        // Check if the paragraph contains only a link
                        const hasOnlyLink = node.children.length === 1 && node.children[0].type === 'link';
                        if (hasOnlyLink) {
                          return <>{children}</>;
                        }
                        return <p {...props} className="mb-2">{children}</p>;
                      },
                      ul: ({ node, ...props }) => (
                        <ul {...props} className="list-disc ml-4 mb-2" />
                      ),
                      ol: ({ node, ...props }) => (
                        <ol {...props} className="list-decimal ml-4 mb-2" />
                      ),
                      li: ({ node, ...props }) => (
                        <li {...props} className="mb-1" />
                      ),
                      code: ({ node, ...props }) => (
                        <code {...props} className="bg-gray-100 rounded px-1 text-sm" />
                      ),
                    }}
                  >
                    {message.message}
                  </ReactMarkdown>
                </div>
              </div>
            )}
          </div>
        </div>
      ))}
    </>
  );
};

export default ThreadMessages;
