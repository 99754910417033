import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import RecoveryPasswordWrapper from "../components/RecoveryPasswordWrapper";

import { supabase } from "../supabaseClient";
import { KeyIcon } from "@heroicons/react/24/outline";
import { ArrowLeftIcon } from "@heroicons/react/20/solid";

const RecoverPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  const submit = async e => {
    e.preventDefault();

    await supabase.auth.resetPasswordForEmail(email, {
      redirectTo: `${process.env.REACT_APP_APP_URL}/password-reset?type=recovery&access_token`,
    });

    navigate(`/recover-password/intent?email=${email}`);
  };

  return (
    <RecoveryPasswordWrapper>
      <form
        onSubmit={submit}
        className="items-center flex justify-center flex-col px-4 z-10"
      >
        <div className="rounded-xl border p-3 border-gray-300 bg-white">
          <KeyIcon className="w-8" />
        </div>

        <h2 className="text-2xl font-bold leading-9 tracking-tight text-gray-900 mt-4 text-center">
          Forgot password?
        </h2>
        <p className="text-center text-gray-500">
          No worries, we’ll send you reset instructions.
        </p>
        <div className="w-full mt-2">
          <label
            htmlFor="email"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Email
          </label>
          <div className="mt-2">
            <input
              type="email"
              id="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              placeholder="email@supportmagic.ai"
              className="block w-full rounded-md border-0 p-2 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        <button
          type="submit"
          className="mt-6 flex w-full justify-center rounded-md bg-secondary px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Reset password
        </button>
        <Link
          to="/onboarding/signin"
          className="flex items-center gap-2 text-sm leading-6 text-gray-500 font-semibold mt-6"
        >
          <ArrowLeftIcon className="w-5" />
          <span>Back to log in</span>
        </Link>
      </form>
    </RecoveryPasswordWrapper>
  );
};

export default RecoverPassword;
