import React from "react";
import { useNavigate } from "react-router-dom";

const urls = {
  setup: [
    { key: "knowledge-sources", name: "Knowledge Sources" },
    { key: "branding", name: "Branding" },
    { key: "intents", name: "Intents" },
    { key: "chatbot", name: "Test" },
    { key: "deploy", name: "Deploy" },
  ],
  settings: [
    { key: "company_info", name: "Company Info" },
    { key: "add_team", name: "Team" },
    { key: "manage_billing", name: "Billing" },
  ],
};

const GeneralSetupTab = ({ title, subtitle, pageType, activeTab }) => {
  const navigate = useNavigate();
  const setActiveTab = newTab => {
    if (newTab === activeTab) return;

    if (pageType === "setup") {
      navigate(`/setup/${newTab}`);
    } else {
      navigate(`/settings/${newTab}`);
    }
  };
  return (
    <div>
      <h1 className="text-lg sm:text-xl">{title}</h1>
      <h5 className="text-sm text-gray-400 mb-8 min-h-8 max-h-20">
        {subtitle}
      </h5>

      <div className="mb-8">
        <nav className="flex space-x-1 sm:space-x-4 border-b-2 text-xs sm:text-md">
          {urls[`${pageType}`].map((setupObj, index) => (
            <button
              key={index}
              className={`font-semibold px-3 py-2 rounded-md ${activeTab === setupObj.key ? "text-primary border-b-2 border-primary rounded-none" : "text-gray-500"}`}
              onClick={() => setActiveTab(setupObj.key)}
            >
              {setupObj.name}
            </button>
          ))}
        </nav>
      </div>
    </div>
  );
};

export default GeneralSetupTab;
