import React, { Fragment, useEffect, useState } from "react";
import GeneralSetupTab from "../../components/GeneralSetupTab";
import CenterWrapper from "../../components/CenterWrapper";
import { ArrowUpRightIcon, CheckCircleIcon } from "@heroicons/react/24/outline";
import api from "../../api";
import { useBillingContext } from "../../BillingContext";
import { fixUnixTime, unixTimeToDate } from "../../utils/dates";
import { Dialog, DialogPanel, Transition, TransitionChild } from "@headlessui/react";
import CheckoutForm from "../../components/CheckoutForm";
import GlobalLoadingSpinner from "../../components/GlobalSpinner";
import MasterCardPNG from "../../imgs/mastercard.png";
import VisaPNG from "../../imgs/visa.png";

const initPlanSelected = {
  name: null,
  product_id: null,
  cost: 0,
  option: "Current Plan Selected",
}

const ManageBilling = () => {
  const { billingInfo } = useBillingContext();
  const [isCardModalOpen, setIsCardModalOpen] = useState(false);
  const [paymentPlans, setPaymentPlans] = useState([]);
  const [billingHistory, setBillingHistory] = useState([]);
  const openCardModal = () => setIsCardModalOpen(true);
  const closeCardModal = () => setIsCardModalOpen(false);
  const [selectedPlan, setSelectedPlan] = useState(initPlanSelected);
  const [loading, setLoading] = useState(false);
  const [userCardInfo, setUserCardInfo] = useState({});

  useEffect(() => {
    const fetchBillingData = async () => {
      setLoading(true);
      try {
        const [invoiceRes, cardInfo, plansRes] = await Promise.allSettled([
          api.get("/api/invoices"),
          api.get("/api/card_info"),
          api.get("/api/company/payment_plans"),
        ]);

        if (invoiceRes.status === "fulfilled") {
          setBillingHistory(invoiceRes.value.data?.invoices || []);
        }
        if (plansRes.status === "fulfilled") {
          setPaymentPlans(plansRes.value.data || []);
        }
        if (cardInfo.status === "fulfilled") {
          setUserCardInfo(cardInfo.value.data.card_info || {});
        }
      } catch (error) {
        console.error("Error fetching billing data:", error);
      } finally {
        setLoading(false);
      }
    };

    if (billingInfo?.active_plan) {
      setSelectedPlan((prev) => ({
        ...prev,
        name: billingInfo.active_plan.name,
        option: "Current Plan Selected",
      }));
    }

    fetchBillingData();
  }, [billingInfo]);

  const planSet = (currentPlanCost, selectedCost) => {
    if (currentPlanCost === selectedCost) {
      return "Current Plan Selected";
    }

    return currentPlanCost > selectedCost ? "Downgrade" : "Upgrade"
  }

  const handleCardImage = (brand) => {
    if (brand === 'visa') {
      return VisaPNG;
    } else {
      return MasterCardPNG;
    }
  }

  return (
    <>
      <CenterWrapper>
        <GlobalLoadingSpinner loading={loading} />
        <div className="" style={{ paddingBottom: "10px" }}>
          <GeneralSetupTab
            title={"Account Settings"}
            subtitle={"Update your company settings, team and billing."}
            pageType={"settings"}
            activeTab={"manage_billing"}
          />

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-12">
            <div className="flex flex-col justify-between border border-gray-200 rounded-xl shadow-md bg-white">
              <div className="rounded-lg p-6">
                <div className="flex flex-col justify-between mt-4">
                  <div className="flex items-center justify-between">
                    <h3 className="text-lg font-semibold text-gray-900">
                      <span className="mr-2">
                        {billingInfo?.active_plan?.name}
                      </span>
                      <span className="text-xs text-indigo-600 bg-indigo-100 px-2 py-1 rounded-full font-medium">
                        Monthly
                      </span>
                    </h3>

                    <div className="flex items-center">
                      <span className="text-xl font-bold text-gray-900">$</span>
                      <span className="text-4xl font-bold text-gray-900">
                        {billingInfo?.active_plan?.cost}
                      </span>
                      <span className="text-gray-600 ml-1 text-sm">
                        per month
                      </span>
                    </div>
                  </div>

                  <p className="text-gray-600 text-sm mt-1">
                    Renews on {billingInfo.next_renewal} - Messages do not roll
                    over.
                  </p>
                </div>

                <div className="mt-4">
                  <p className="text-sm text-gray-600">
                    {billingInfo.credits_consumed}/
                    {billingInfo.subscription_limit} messages used
                  </p>
                  <div className="w-full bg-gray-200 h-2 rounded-full mt-1">
                    <div
                      className="bg-purple-600 h-2 rounded-full"
                      style={{
                        width: `${(billingInfo.credits_consumed / billingInfo.subscription_limit) * 100}%`,
                      }}
                    ></div>
                  </div>
                </div>
              </div>
              <div className="flex p-4 items-center justify-end border-t mt-2 text-sm text-gray-500">
                <button
                  onClick={openCardModal}
                  className="font-semibold text-primary mr-2"
                >
                  Upgrade Plan
                </button>
                <ArrowUpRightIcon
                  aria-hidden="true"
                  className="size-4 text-primary"
                />
              </div>
            </div>

            {userCardInfo && (<div className="border rounded-lg p-6 shadow-md ">
              <h3 className="text-lg font-semibold ">
                Payment method
              </h3>
              <p className="text-gray-600 text-sm">
                Change how you pay for your plan.
              </p>
              <div className="mt-3 flex items-center border p-4 rounded-lg bg-white">
                <img
                  src={`${handleCardImage(userCardInfo?.brand)}`}
                  alt={`${userCardInfo?.brand}`}
                  className="h-6 mr-3"
                />
                <div>
                  <p className="text-gray-800 text-sm font-medium">
                    {userCardInfo?.brand?.toUpperCase()} ending in {userCardInfo.last4}
                  </p>
                  <p className="text-gray-600 text-xs">Expiry {userCardInfo.exp_year}</p>
                  <p className="text-gray-600 text-xs">
                    {userCardInfo.billing_email}
                  </p>
                </div>
                <button
                  className="ml-auto text-indigo-600 text-sm font-semibold"
                  onClick={() =>
                    {
                      window.location.href = 'https://stripe.com/billing'
                    }
                }>
                  Edit
                </button>
              </div>
            </div>)}
          </div>

          <h2 className="text-lg font-bold mb-1">Billing and invoicing</h2>
          <p className="text-sm text-gray-600 mb-4">Review your bills</p>

          <div className="mx-4 mt-10 ring-1 ring-gray-300 sm:mx-0 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    Invoice
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                  >
                    Billing Date
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                  >
                    Amount
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                  >
                    Plan
                  </th>

                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                  ></th>
                </tr>
              </thead>
              <tbody>
                {billingHistory.map((invoice, index) => (
                  <tr
                    className="even:bg-gray-50 border-t hover:bg-gray-50 text-sm"
                    key={invoice}
                  >
                    <td className="py-3 px-4 flex items-center">
                      <span className="bg-red-500 text-white text-xs font-semibold px-2 py-1 rounded">
                        PDF
                      </span>
                      <span className="ml-3 text-gray-700">
                        Invoice #{index} – {unixTimeToDate(invoice.created)}
                      </span>
                    </td>
                    <td className="hidden px-3 py-3.5 text-sm text-gray-500 sm:table-cell">
                      <p className="font-medium text-gray-900">
                        {fixUnixTime(invoice.created)}
                      </p>
                    </td>
                    <td className="hidden px-3 py-3.5 text-sm text-gray-500 sm:table-cell">
                      <p className="font-medium text-gray-800">
                        <span className="px-3 py-1 text-sm font-medium bg-green-100 text-green-700 rounded-full">
                          Paid
                        </span>
                      </p>
                    </td>
                    <td className="hidden px-3 py-3.5 text-sm text-gray-500 sm:table-cell">
                      <p className="font-medium text-gray-900">
                        ${invoice.amount_due}
                      </p>
                    </td>
                    <td className="hidden px-3 py-3.5 text-sm text-gray-500 sm:table-cell">
                      <p className="font-medium text-gray-900">Basic Plan</p>
                    </td>
                    <td className="py-3 px-4 flex items-center">
                      <a
                        href={invoice.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="bg-white text-black text-xs font-semibold px-1 py-1 rounded"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="size-5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
                          />
                        </svg>
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <Transition show={isCardModalOpen} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={closeCardModal}>
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </TransitionChild>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
                <TransitionChild
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-6">
                    <div className="">
                      <header className="mb-6">
                        <div className="flex items-center space-x-2">
                          <div className="w-12 h-12 mr-3 rounded-lg border-2 border-grey-300 overflow-hidden flex items-center justify-center">
                            <div className="w-6 h-6 flex items-center justify-center">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                aria-hidden="true"
                                data-slot="icon"
                                className="text-gray-600"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M6.429 9.75 2.25 12l4.179 2.25m0-4.5 5.571 3 5.571-3m-11.142 0L2.25 7.5 12 2.25l9.75 5.25-4.179 2.25m0 0L21.75 12l-4.179 2.25m0 0 4.179 2.25L12 21.75 2.25 16.5l4.179-2.25m11.142 0-5.571 3-5.571-3"
                                ></path>
                              </svg>
                            </div>
                          </div>
                          <div>
                            <div className="font-semibold text-sm text-gray-600">
                              Select Plan
                            </div>
                            <div className="text-sm text-gray-500">
                              Simple and flexible per-message pricing.
                            </div>
                          </div>
                        </div>
                      </header>

                      <hr className="border-t border-gray-300" />

                      <main className="grid md:grid-cols-4 gap-4 py-4">
                        {paymentPlans.map((plan, index) => {
                          const isDisabled =
                            index === 0 &&
                            billingInfo?.active_plan?.cost > plan.cost;

                          return (
                            <div
                              key={plan.name}
                              onClick={() => {
                                if (!isDisabled) {
                                  setSelectedPlan({
                                    ...selectedPlan,
                                    name: plan.name,
                                    cost: plan.cost,
                                    product_id: plan.stripe_product_id,
                                    option: planSet(
                                      billingInfo?.active_plan?.cost,
                                      plan.cost
                                    ),
                                  });
                                }
                              }}
                              className={`border-2 ${
                                plan.name === selectedPlan.name
                                  ? "border-purple-500"
                                  : ""
                              } rounded-lg p-6 relative ${
                                isDisabled
                                  ? "opacity-50 cursor-not-allowed"
                                  : "cursor-pointer"
                              }`}
                            >
                              <label className="absolute top-4 right-4 w-5 h-5">
                                <input
                                  type="radio"
                                  name="radio"
                                  checked={plan.name === selectedPlan.name}
                                  onChange={() => {
                                    if (!isDisabled) {
                                      setSelectedPlan({
                                        ...selectedPlan,
                                        name: plan.name,
                                        cost: plan.cost,
                                        product_id: plan.stripe_product_id,
                                        option: planSet(
                                          billingInfo?.active_plan?.cost,
                                          plan.cost
                                        ),
                                      });
                                    }
                                  }}
                                  disabled={isDisabled}
                                  className="peer hidden"
                                />
                                <div
                                  className={`w-6 h-6 rounded-full border-8 ${
                                    plan.name === selectedPlan.name
                                      ? "border-[#9E77ED]"
                                      : "border-gray-300"
                                  } flex items-center justify-center`}
                                >
                                  <div className="w-2 h-2 bg-white rounded-full"></div>
                                </div>
                              </label>
                              <h3 className="text-lg font-semibold">
                                ${plan.cost}/month
                              </h3>
                              <p className="text-gray-600">{plan.name}</p>
                              <p className="text-gray-500 text-sm mt-2">
                                Billed monthly
                              </p>
                              <ul className="mt-4 space-y-2 text-xs">
                                {plan.benefits
                                  .split(",")
                                  .map((benefit, idx) => (
                                    <li key={idx} className="flex items-center">
                                      <CheckCircleIcon className="size-5 text-primary" />{" "}
                                      {benefit}
                                    </li>
                                  ))}
                              </ul>
                            </div>
                          );
                        })}
                      </main>
                      <hr className="border-t border-gray-300" />

                      <footer className="py-4 flex justify-between items-center">
                        <button className="px-4 py-2 bg-gray-200 rounded">
                          Chat with us
                        </button>
                        <div className="flex space-x-4">
                          <button
                            className="px-4 py-2 bg-gray-200 rounded"
                            onClick={() => {
                              closeCardModal();
                              setSelectedPlan({
                                ...selectedPlan,
                                name: billingInfo?.active_plan?.name,
                                option: "Current Plan Selected",
                              });
                            }}
                          >
                            Cancel
                          </button>

                          {billingInfo?.active_plan?.name ===
                          selectedPlan.name ? (
                            <span
                              className="px-4 py-2 bg-gray-300 rounded cursor-not-allowed opacity-50"
                              disabled="true"
                            >
                              Current Plan Selected
                            </span>
                          ) : (
                            <CheckoutForm
                              planId={selectedPlan.product_id}
                              cost={selectedPlan.cost}
                              option={selectedPlan.option}
                              setLoading={setLoading}
                            />
                          )}
                        </div>
                      </footer>
                    </div>
                  </DialogPanel>
                </TransitionChild>
              </div>
            </div>
          </Dialog>
        </Transition>
      </CenterWrapper>
    </>
  );
}

export default ManageBilling;
