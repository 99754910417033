import React from "react";
import { Link } from "react-router-dom";
import logo from "./logo.png";
import createChatbot from "./onboarding/create-chatbot-text.png";

const NotFound = () => {
  return (
    <div className="container mx-auto text-center p-4">
      <header className="landing-header flex items-center justify-between p-4 relative">
        <img src={logo} alt="SupportMagic" className="logo-img h-10 md:h-12" />
        <Link to="/onboarding/signin">
          <button className="font-semibold learn-more-button ml-4 px-6 py-2 border border-gray-300 rounded-lg text-gray-400 bg-transparent hover:bg-gray-200 text-sm font-plus-jakarta-sans">
            Sign in →
          </button>
        </Link>
      </header>

      <main className="main-content py-12 flex flex-col justify-center items-center">
        <h1 className="text-4xl font-bold text-red-600">
          Oops! Page Not Found
        </h1>
        <p className="text-sm text-gray-600 my-2 mx-6 md:mx-0 font-plus-jakarta-sans">
          The page you're looking for doesn't exist. Try going back to the
          homepage.
        </p>

        <Link to="/" className="flex justify-center">
          <button className="mt-4 text-white bg-[#30a46c] border-none rounded-[4px] cursor-pointer w-[150px] px-4 py-4">
            Go to Homepage
          </button>
        </Link>
      </main>

      <div className="mb-[75px] md:mb-[100px] lg:mb-[150px]"></div>
    </div>
  );
};

export default NotFound;
