import React from "react";
import { Link, useSearchParams } from "react-router-dom";

import RecoveryPasswordWrapper from "../components/RecoveryPasswordWrapper";

import { supabase } from "../supabaseClient";
import { EnvelopeIcon } from "@heroicons/react/24/outline";
import { ArrowLeftIcon } from "@heroicons/react/20/solid";

const RecoverPasswordIntent = () => {
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");

  const resend = async e => {
    e.preventDefault();

    await supabase.auth.resetPasswordForEmail(email, {
      redirectTo: `${process.env.REACT_APP_APP_URL}/password-reset?type=recovery&access_token`,
    });
  };

  const open = () => {
    window.location.href = "https://gmail.com";
  };

  return (
    <RecoveryPasswordWrapper>
      <div className="items-center flex justify-center flex-col px-4 z-10">
        <div className="rounded-xl border p-3 border-gray-300 bg-white">
          <EnvelopeIcon className="w-8" />
        </div>

        <h2 className="text-2xl font-bold leading-9 tracking-tight text-gray-900 mt-4 text-center">
          Check your email
        </h2>
        <div className="text-center text-gray-500">
          <span>We sent a password reset link to</span>
          <br />
          <b>{email}</b>
        </div>

        <button
          onClick={open}
          type="button"
          className="mt-6 flex w-full justify-center rounded-md bg-secondary px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Open email app
        </button>

        <div className="mt-6 text-sm leading-6 text-gray-500">
          <span>Didn’t receive the email?</span>

          <button
            type="button"
            onClick={resend}
            className="font-semibold text-indigo-600 hover:text-indigo-500"
          >
            <span className="ml-1">Click to resend</span>
          </button>
        </div>

        <Link
          to="/onboarding/signin"
          className="flex items-center gap-2 text-sm leading-6 text-gray-500 font-semibold mt-6"
        >
          <ArrowLeftIcon className="w-5" />
          <span>Back to log in</span>
        </Link>
      </div>
    </RecoveryPasswordWrapper>
  );
};

export default RecoverPasswordIntent;
