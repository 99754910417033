import React, { createContext, useContext, useState, useEffect } from "react";
import { supabase } from "./supabaseClient";
import api from "./api.js";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getSession = async () => {
      const {
        data: { session },
      } = await supabase.auth.getSession();
      setUser(session?.user ?? null);
      if (session) {
        sessionStorage.setItem("supabase_jwt", session.access_token);
      }
      setLoading(false);
    };

    getSession();

    const { data: authListener } = supabase.auth.onAuthStateChange(
      (_event, session) => {
        setUser(session?.user ?? null);
        if (session) {
          sessionStorage.setItem("supabase_jwt", session.access_token);
        } else {
          sessionStorage.removeItem("supabase_jwt");
        }
        setLoading(false);
      }
    );

    return () => {
      authListener?.subscription?.unsubscribe();
    };
  }, []);

  const signInWithGoogle = async () => {
    try {
      const { error } = await supabase.auth.signInWithOAuth({
        provider: "google",
        options: {
          redirectTo: window.location.origin + "/callback",
        },
      });
      if (error) {
        console.error("Error during Google login:", error.message);
      }
    } catch (error) {
      console.error("Unexpected error during Google login:", error);
    }
  };

  const signUpWithEmail = async (email, password) => {
    try {
      const { data, error } = await supabase.auth.signUp({
        email: email,
        password: password,
      });

      if (error) {
        console.error("Error during email sign up:", error.message);
        throw error;
      }

      if (data.user) {
        await api.post("/api/create-user", {
          email: email,
        });
        window.location.href = window.location.origin + "/callback?signup=true";
      }
    } catch (error) {
      console.error("Unexpected error during email sign up:", error);
      throw error;
    }
  };

  const signInWithEmail = async (email, password) => {
    try {
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });
      if (error) {
        console.error("Error during email sign in:", error.message);
        throw error; // Re-throw error to be caught in the component
      } else {
        setUser(data.user);
      }
    } catch (error) {
      console.error("Unexpected error during email sign in:", error);
      throw error; // Re-throw error to be caught in the component
    }
  };

  const signOut = async () => {
    await supabase.auth.signOut();
    setUser(null);
    sessionStorage.removeItem("supabase_jwt");
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        signInWithGoogle,
        signUpWithEmail,
        signInWithEmail,
        signOut,
      }}
    >
      {!loading && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
