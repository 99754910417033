import React, { useState, useEffect, useCallback } from "react";

import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import ConfirmationDialog from "../../components/ConfirmationDialog";

import { Switch } from "@headlessui/react";

import { formatCompactTime } from "../../utils/dates";
import { classNames } from "../../utils/styles.js";

import SetupTab from "./components/SetupTab.jsx";

import GlobalLoadingSpinner from "../../components/GlobalSpinner.jsx";
import { PaginationRow } from "../../components/PaginationRow.jsx";

import api from "../../api.js";
import AddIntentModal from "./components/AddIntentModal.jsx";
import EditIntentModal from "./components/EditIntentModal.jsx";
import CenterWrapper from "../../components/CenterWrapper.jsx";
import GeneralSetupTab from "../../components/GeneralSetupTab";

const Intents = () => {
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [intentToDelete, setIntentToDelete] = useState(undefined);
  const [intents, setIntents] = useState([]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [editIntentId, setEditIntentId] = useState(null);

  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const pageSize = 10;
  const [totalIntents, setTotalIntents] = useState(0);

  const totalPages = Math.ceil(totalIntents / pageSize);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleEdit = intent => {
    setEditIntentId(intent.id);

    setIsEditModalVisible(true);
  };

  const fetchIntents = useCallback(async () => {
    const chatbotUuid = localStorage.getItem("chatbot_uuid");
    if (!chatbotUuid) {
      console.error("Chatbot UUID not found");
      return;
    }
    try {
      setLoading(true);
      const { data } = await api.get(`/api/intents`, {
        params: {
          chatbot_uuid: chatbotUuid,
          page,
          page_size: pageSize,
        },
      });

      setTotalIntents(data.total);
      console.log(data.data);
      setIntents(data.data);
    } catch (err) {
      console.error("Failed to fetch intents", err);
    } finally {
      setLoading(false);
    }
  }, [page, pageSize]);

  useEffect(() => {
    fetchIntents();
  }, [fetchIntents]);

  const handleRemove = async intent => {
    try {
      await api.delete(`/api/intents/${intent.id}`);
      setIsConfirmModalVisible(false);
      fetchIntents();
    } catch (err) {
      console.error("Failed to remove intent", err);
    }
  };

  const setLive = async intent => {
    setLoading(true);
    try {
      const chatbotUuid = localStorage.getItem("chatbot_uuid");

      await api.put(`/api/intents/${intent.id}`, {
        set_live: !intent.set_live,
        chatbot_uuid: chatbotUuid,
      });

      fetchIntents();
    } catch (err) {
      console.error("Failed to update intent", err);
    }
  };

  return (
    <CenterWrapper>
      <GeneralSetupTab
        title={"Intents"}
        subtitle={
          "Utilize intents to help your AI agent associate customer intents from conversations to actions the AI should take on your behalf. This provides you ultimate control to automate as much of your business operations as you want."
        }
        pageType={"setup"}
        activeTab={"intents"}
      />

      <GlobalLoadingSpinner loading={loading} />

      <div className="bg-white mb-8">
        <div className="flex justify-between items-center mb-4">
          <div>
            <h2 className="text-md font-semibold">Intents</h2>
            <p className="text-sm text-gray-600">
              Intents are used by your AI Agent to understand a specific
              customer intent and how to react in those situations.
            </p>
          </div>
          <button
            className="flex items-center px-4 py-2 border-2 border-gray-200 text-gray-700 text-xs font-medium rounded-lg hover:bg-gray-200"
            onClick={() => showModal()}
          >
            + Add intent
          </button>
        </div>

        <div className="-mx-4 mt-10 ring-1 ring-gray-300 sm:mx-0 sm:rounded-lg">
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                >
                  Name
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                >
                  Category
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                >
                  Created at
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                >
                  Updated at
                </th>
                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                  Status
                </th>
                <th
                  scope="col"
                  className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                ></th>
              </tr>
            </thead>

            <tbody>
              {intents.map((intent, index) => (
                <tr
                  key={index}
                  className="even:bg-gray-50 border-t hover:bg-gray-50 text-sm"
                >
                  <td
                    className={classNames(
                      index === 0 ? "" : "border-t border-gray-200",
                      "relative py-4 pl-4 pr-3 text-sm sm:pl-6"
                    )}
                  >
                    <p className="text-gray-700">{intent.type}</p>
                  </td>

                  <td
                    className={classNames(
                      index === 0 ? "" : "border-t border-gray-200",
                      "hidden px-3 py-3.5 text-sm text-gray-500 sm:table-cell"
                    )}
                  >
                    <p className="font-medium text-gray-800">
                      <span className="inline-flex items-center px-2 py-1 rounded-md text-sm font-medium border shadow">
                        {intent.category}
                      </span>
                    </p>
                  </td>

                  <td
                    className={classNames(
                      index === 0 ? "" : "border-t border-gray-200",
                      "hidden px-3 py-3.5 text-sm text-gray-500 sm:table-cell"
                    )}
                  >
                    <p className="font-medium text-gray-900">
                      {formatCompactTime(intent.created_at)}
                    </p>
                  </td>

                  <td
                    className={classNames(
                      index === 0 ? "" : "border-t border-gray-200",
                      "hidden px-3 py-3.5 text-sm text-gray-500 sm:table-cell"
                    )}
                  >
                    <p className="font-medium text-gray-900">
                      {formatCompactTime(intent.updated_at)}
                    </p>
                  </td>

                  <td
                    className={classNames(
                      index === 0 ? "" : "border-t border-gray-200",
                      "hidden px-3 py-3.5 text-sm text-gray-500 sm:table-cell"
                    )}
                  >
                    <p className="font-medium text-gray-900">
                      <Switch
                        checked={intent.set_live}
                        onChange={() => setLive(intent)}
                        className="group relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 data-[checked]:bg-primary"
                      >
                        <span className="sr-only">Use setting</span>
                        <span
                          aria-hidden="true"
                          className="pointer-events-none inline-block size-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
                        />
                      </Switch>
                    </p>
                  </td>

                  <td className="py-4 px-6 text-right">
                    <Menu as="div" className="relative flex-none">
                      <MenuButton className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                        <span className="sr-only">Open options</span>
                        <EllipsisVerticalIcon
                          aria-hidden="true"
                          className="h-5 w-5"
                        />
                      </MenuButton>
                      <MenuItems
                        transition
                        className="absolute right-0 z-10 mt-2 w-20 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                      >
                        <MenuItem>
                          <a
                            className="cursor-pointer block px-3 py-1 text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50"
                            onClick={() => handleEdit(intent)}
                          >
                            Edit
                          </a>
                        </MenuItem>
                        <MenuItem>
                          <a
                            className="cursor-pointer block px-3 py-1 text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50"
                            onClick={() => {
                              setIntentToDelete(intent);
                              setIsConfirmModalVisible(true);
                            }}
                          >
                            Delete
                          </a>
                        </MenuItem>
                      </MenuItems>
                    </Menu>
                  </td>
                </tr>
              ))}

              <tr key="pagination-row" className="border-t text-sm">
                <td colSpan="100" className="relative text-sm sm:pl-6">
                  <div className="flex items-center justify-between bg-white px-4 py-3 sm:px-6 mt-4">
                    <PaginationRow
                      totalPages={totalPages}
                      currentPage={page}
                      setPage={setPage}
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {isModalVisible && (
        <AddIntentModal
          isOpen={isModalVisible}
          onClose={() => setIsModalVisible(false)}
          onSuccess={() => {
            setIsModalVisible(false);
            fetchIntents();
          }}
          setLoading={setLoading}
          api={api}
        />
      )}

      {isEditModalVisible && (
        <EditIntentModal
          intentId={editIntentId}
          isOpen={isEditModalVisible}
          onClose={() => setIsEditModalVisible(false)}
          onSuccess={() => {
            setIsEditModalVisible(false);
            fetchIntents();
          }}
          setLoading={setLoading}
          api={api}
        />
      )}

      {isConfirmModalVisible && (
        <ConfirmationDialog
          isVisible={isConfirmModalVisible}
          onClose={() => setIsConfirmModalVisible(false)}
          onCancel={() => {
            setIntentToDelete(undefined);
            setIsConfirmModalVisible(false);
          }}
          onConfirm={() => {
            handleRemove(intentToDelete);
          }}
          title={"Delete intent?"}
          description={"Are you sure you want to delete this intent?"}
          confirmLabel="Yes"
          cancelLabel="Cancel"
          useCase="negative"
        />
      )}
    </CenterWrapper>
  );
};

export default Intents;
