import React, { useState } from "react";

import { WIDGET_URL } from "../../../api.js";

import { DocumentDuplicateIcon } from "@heroicons/react/24/outline";

import { CopyToClipboard } from "react-copy-to-clipboard";

import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { solarizedlight } from "react-syntax-highlighter/dist/esm/styles/prism";
import { CheckIcon } from "@heroicons/react/20/solid";

const BasicDeploy = ({
  tenantId,
  secondaryColor,
  fontColor,
  companyName,
  companyLogo,
}) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 3000);
  };

  const embedCode = `<script>
    const APP_ID = "${tenantId}";

    window.supportMagicChatSettings = {
      app_id: APP_ID,
      companyName: "${companyName}",
      chatColor: "${secondaryColor}",
      buttonColor: "${fontColor}",
      companyLogo: "${companyLogo}",
    };
  </script>

  <script>
    (function () { var w = window, bc = w.SupportMagicChat; if (typeof bc === "function") { bc("update", w.supportMagicChatSettings); } else { var d = document, i = function () { i.c(arguments); }; i.q = []; i.c = function (args) { i.q.push(args); }; w.SupportMagicChat = i; var l = function () { var s = d.createElement("script"); s.type = "text/javascript"; s.async = true; s.src = "${WIDGET_URL}/chat-widget.js"; var x = d.getElementsByTagName("script")[0]; x.parentNode.insertBefore(s, x); }; if (document.readyState === "complete") { l(); } else if (w.attachEvent) { w.attachEvent("onload", l); } else { w.addEventListener("load", l, false); } } })();
  </script>`;

  return (
    <section className="mt-8">
      <h2 className="text-lg font-semibold mb-0">
        Manual Installation for Live Chat Widget
      </h2>
      <p className="text-gray-600 text-sm">
        Add the widget to the bottom right corner of your website by copying and
        pasting this code. Add this code to your website’s HTML, just before the
        closing &lt;/body&gt; tag.
      </p>

      <div className="mt-6">
        <style>
          {`
          .custom-select {
            appearance: none;
            border: 1px solid #b0b0b0;
            padding-right: 2.5rem;
            background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg height='36' width='36' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 8L12 13L17 8' stroke='%23666' stroke-width='2' fill='none'/%3E%3C/svg%3E"); background-repeat: no-repeat;
            background-position: right 0.5rem center;
            background-size: 1rem;
          }
          .custom-select:focus {
            outline: none;
            border-color: #666;
          }
        `}
        </style>
      </div>

      <div className="relative">
        {/* Line Numbers Container */}
        <div className="text-white w-full border-2 rounded-2xl flex-shrink-0">
          <SyntaxHighlighter
            language="javascript"
            style={solarizedlight}
            showLineNumbers={true}
            customStyle={{
              backgroundColor: "white",
              padding: "20px",
              minWidth: "40px", // Keeps the line number column from shrinking
              marginRight: "10px",
              whiteSpace: "normal", // Ensures text wraps
            }}
          >
            {embedCode}
          </SyntaxHighlighter>
        </div>

        <div className="absolute top-0 right-0 mt-4 mr-4">
          {isCopied ? (
            <button className="px-4 py-2 border-2 border-gray-300 text-xs font-bold text-gray-700 rounded-md shadow-sm bg-gray-50 flex items-center space-x-1 cursor-not-allowed pointer-none">
              <CheckIcon
                aria-hidden="true"
                className="h-6 w-6 text-emerald-700"
              />
              <span>Copied!</span>
            </button>
          ) : (
            <CopyToClipboard text={embedCode} onCopy={handleCopy}>
              <button className="px-4 py-2 border-2 border-gray-300 text-xs font-bold text-gray-700 rounded-md shadow-sm bg-gray-50 hover:bg-gray-200 flex items-center space-x-1">
                <DocumentDuplicateIcon
                  aria-hidden="true"
                  className="h-6 w-6 text-gray-700"
                />
                <span>Copy</span>
              </button>
            </CopyToClipboard>
          )}
        </div>
      </div>
    </section>
  );
};

export default BasicDeploy;
