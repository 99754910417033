import React from "react";
import { ReactComponent as CloseButton } from "../../imgs/icons/buttons/close.svg";
import { formatDate } from "../../utils/dates";
import { useNavigate } from "react-router-dom";
import GenericAvatar from "../AIInbox/GenericAvatar";

const ItemModal = ({
  setIsModalVisible,
  singleTask,
  setSingleTask,
  handleConversation,
}) => {
  const navigate = useNavigate();

  const viewConversation = conversation => {
    navigate(`/dashboard/ai-inbox?only=${singleTask.task.conversation_uuid}`);
  };

  return (
    <>
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
        <div className="bg-white w-full max-w-md rounded-lg shadow-lg p-6 relative">
          <button className="absolute top-3 right-2 text-gray-400 hover:text-gray-600 focus:outline-none">
            <CloseButton
              className="mr-2 cursor-pointer"
              onClick={() => {
                setIsModalVisible(false);
                setSingleTask({});
              }}
            />
          </button>

          <div className="flex flex-col items-center">
            <GenericAvatar
              firstName={singleTask.task.customer?.first_name}
              lastName={singleTask.task.customer?.last_name}
            />
            <h2 className="mt-3 text-xl font-semibold text-gray-800">
              Ticket #{singleTask.task.conversation_id}
            </h2>
            <p className="text-sm text-gray-500">
              {formatDate(singleTask.task.last_message_time)}
            </p>
          </div>

          <div className="mt-4 flex space-x-2">
            <span
              className={`px-3 py-1 text-sm font-medium ${singleTask.task.resolved ? "bg-blue-100 text-blue-600" : "bg-orange-100 text-orange-600"} rounded-lg`}
            >
              {singleTask.task.resolved ? `Closed` : `Open`}
            </span>
            {singleTask.task.intents.map((intent, index) => (
              <span key={`${intent.id}-${index}`} className="px-3 py-1 text-sm font-medium bg-green-100 text-green-700 rounded-lg">
                {intent?.type}
              </span>
            ))}
          </div>

          <div className="mt-6">
            <h3 className="text-sm font-bold text-gray-700">
              Summary of Conversation
            </h3>
            <p className="text-sm text-gray-600 mt-1">
              {singleTask.task.summary ?? "N/A"}
            </p>
          </div>

          <div className="mt-4">
            <h3 className="text-sm font-bold text-gray-700">Action</h3>
            <p className="text-sm text-gray-600 mt-1">
              {singleTask.task.action}
            </p>
          </div>

          <div className="mt-4">
            <h3 className="text-sm font-bold text-gray-700">Data</h3>
            <ul className="mt-1 space-y-2">
              <li className="flex items-center text-sm text-gray-600">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                  />
                </svg>
                <span className="ml-2">{singleTask.task.customer?.email}</span>
              </li>
              <li className="flex items-center text-sm text-gray-600">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
                <span className="ml-2">Last Order 143892</span>
              </li>
            </ul>
          </div>

          <div className="mt-4">
            <h3 className="text-sm font-bold text-gray-700">User</h3>
            <div className="flex items-center mt-2">
              <div className="w-10 h-10 bg-gray-100 border border-gray-300 rounded-full flex items-center justify-center text-gray-600 font-medium">
                AL
              </div>
              <div className="ml-3 py-1 text-sm font-medium text-gray-700">
                <p className="text-sm font-semibold text-gray-900 leading-none">
                  {singleTask.task.customer?.first_name +
                    " " +
                    singleTask.task.customer?.last_name}
                </p>
                <p className="text-sm text-gray-500 leading-none mt-0.5">
                  {singleTask.task.customer?.email}
                </p>
              </div>
            </div>
          </div>

          {singleTask.task.resolved && (
            <div className="mt-4">
              <h3 className="text-sm font-bold text-gray-700">Closed By</h3>
              <div className="flex items-center mt-2">
                <div className="w-8 h-8 bg-gray-200 rounded-full flex items-center justify-center text-gray-700 font-bold">
                  CM
                </div>
                <div className="ml-3">
                  <p className="text-sm font-medium text-gray-800">
                    Collin Mann
                  </p>
                  <p className="text-sm text-gray-600">collin@gmail.com</p>
                </div>
              </div>
            </div>
          )}
          <div className="mt-6 space-y-4">
            {singleTask.task.resolved ? (
              <button
                onClick={() => {
                  handleConversation(singleTask.task);
                  setIsModalVisible(false);
                }}
                className="w-full py-2 text-sm font-medium text-white bg-purple-600 rounded-lg hover:bg-purple-700 transition duration-200 flex items-center justify-center space-x-2"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.25 13.5h3.86a2.25 2.25 0 0 1 2.012 1.244l.256.512a2.25 2.25 0 0 0 2.013 1.244h3.218a2.25 2.25 0 0 0 2.013-1.244l.256-.512a2.25 2.25 0 0 1 2.013-1.244h3.859m-19.5.338V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 0 0-2.15-1.588H6.911a2.25 2.25 0 0 0-2.15 1.588L2.35 13.177a2.25 2.25 0 0 0-.1.661Z"
                  />
                </svg>
                <span>Re-open Ticket</span>
              </button>
            ) : (
              <button
                onClick={() => {
                  handleConversation(singleTask.task);
                  setIsModalVisible(false);
                }}
                className="w-full py-2 text-sm font-medium text-white bg-purple-600 rounded-lg hover:bg-purple-700 transition duration-200 flex items-center justify-center space-x-2"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
                <span>Close Ticket</span>
              </button>
            )}
            <button
              onClick={() => viewConversation()}
              className="w-full py-2 text-sm font-medium text-gray-600 bg-gray-100 rounded-lg hover:bg-gray-200 transition duration-200 flex items-center justify-center space-x-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                />
              </svg>
              <span>View Conversation</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ItemModal;
