import GeneralSetupTab from "../../components/GeneralSetupTab";
import React, { Fragment, useEffect, useState } from "react";
import CenterWrapper from "../../components/CenterWrapper";
import api from "../../api";
import AddTeamsModal from "./AddTeamsModal";
import EditTeamMemberModal from "./EditTeamMemberModal";
import GlobalLoadingSpinner from "../../components/GlobalSpinner";
import plainUserAvatar from "../../imgs/icons/emptyUserAvatar.png";
import { ReactComponent as EditButtonSVG } from "../../imgs/icons/pencil.svg";
import { ReactComponent as DeleteButtonSVG } from "../../imgs/icons/trash.svg";
import TeamMemberStatusUpdateModal from "./TeamMemberStatusUpdateModal";

const ROLES = {
  member: "Member",
  admin: "Administrator",
}

const AddTeam = () => {
  const [isNewUserModalOpen, setIsNewUserModalOpen] = useState(false);
  const [isEditUserModalOpen, setIsEditUserModalOpen] = useState(false);
  const [isEditUserStatusModalOpen, setIsEditUserStatusModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [teamMembers, setTeamMembers] = useState([]);
  const [editUserData, setEditUserData] = useState({});

  const fetchTeam = async () => {
    try {
      const response = await api.get(`/api/team`);
      const team = response.data;

      setTeamMembers(team);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      alert(JSON.stringify(err));
    }
  };

  useEffect(() => {
    fetchTeam();
  }, []);

  const handleAddUser = () => setIsNewUserModalOpen(true);

  const handleEditUser = (userObj) => {
    setEditUserData({
      email: userObj.email,
      role: userObj.role,
      active: userObj.active,
      id: userObj.id,
    })
    setIsEditUserModalOpen(true);
  }

  const handleUserStatus = (userObj) => {
    setEditUserData({
      email: userObj.email,
      role: userObj.role,
      active: userObj.active,
      id: userObj.id,
    })
    setIsEditUserStatusModalOpen(true);
  }

  return (
    <CenterWrapper>
      <GlobalLoadingSpinner loading={loading} />
      <GeneralSetupTab
        title={"Account Settings"}
        subtitle={"Update your company settings, team and billing."}
        pageType={"settings"}
        activeTab={"add_team"}
      />

      <div className="bg-white mb-8">
        <div className="flex justify-between items-center mb-4">
          <div>
            <h2 className="text-md font-semibold">Team members</h2>
            <p className="text-sm text-gray-600">
              Manage your team members and accounts permissions here.
            </p>
          </div>
          <button
            onClick={handleAddUser}
            className="flex items-center px-4 py-2 bg-secondary border border-primary text-xs font-medium rounded-lg text-white"
          >
            + Add User
          </button>
        </div>

        <div className="-mx-4 mt-10 ring-1 ring-gray-300 sm:mx-0 sm:rounded-lg">
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                >
                  Name
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                >
                  Status
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                >
                  Email Address
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                >
                  Role
                </th>
                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                  Actions
                </th>
              </tr>
            </thead>

            <tbody>
            {teamMembers.map((person, index) => (
              <tr key={person.id}>
                <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                  <div className="flex items-center">
                    <div className="ml-4 flex-shrink-0">
                      <img
                        alt=""
                        src={person.picture_url || plainUserAvatar}
                        className="h-11 w-11 rounded-full"
                      />
                    </div>
                    <div className="ml-4">
                      <div className="font-medium text-gray-900">
                        {person.first_name} {person.last_name}
                      </div>
                    </div>
                  </div>
                </td>
                <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                  {person.active ? (
                    <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                            Active
                          </span>
                  ) : (
                    <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20">
                            Inactive
                          </span>
                  )}
                </td>
                <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                  {person.email}
                </td>
                <td className="relative whitespace-nowrap py-5 pl-3 pr-4 text-sm font-medium sm:pr-0">
                  {ROLES[person.role] || "Unknown"}
                </td>
                <td className="relative whitespace-nowrap py-6 pl-12 pr-5 text-sm font-medium sm:pr-0 flex items-center space-x-3">
                  {/* Edit Button */}
                  {person.active && (
                    <>
                      <EditButtonSVG
                        onClick={() => handleEditUser(person)}
                        className="w-5 h-5 text-emerald-600 hover:text-emerald-900 cursor-pointer"
                      />
                      <DeleteButtonSVG
                        onClick={() => handleUserStatus(person)}
                        className="w-5 h-5 text-red-500 hover:text-red-900 cursor-pointer"
                      />
                    </>
                  )}

                  {/* Activate Button */}
                  {!person.active && (
                    <EditButtonSVG
                      onClick={() => handleUserStatus(person)}
                      className="w-5 h-5 text-emerald-600 hover:text-emerald-900 cursor-pointer"
                    />
                  )}
                </td>
              </tr>
            ))}


            </tbody>
          </table>
        </div>
      </div>

      {isNewUserModalOpen && (
        <AddTeamsModal
          isOpen={isNewUserModalOpen}
          onClose={() => setIsNewUserModalOpen(false)}
          fetchTeam={fetchTeam}
          onSuccess={() => {
            setIsNewUserModalOpen(false);
          }}
          setLoading={setLoading}
          api={api}
        />
      )}
      {isEditUserModalOpen && (
        <EditTeamMemberModal
          isOpen={isEditUserModalOpen}
          fetchTeam={fetchTeam}
          setLoading={setLoading}
          onSuccess={() => {
            setIsEditUserModalOpen(false);
            setEditUserData({});
          }}
          onClose={() => {
            setIsEditUserModalOpen(false)
            setEditUserData({});
          }}
          editUserData={editUserData}
          api={api}
        />
      )}
      {isEditUserStatusModalOpen && (
        <TeamMemberStatusUpdateModal
          isOpen={isEditUserStatusModalOpen}
          fetchTeam={fetchTeam}
          setLoading={setLoading}
          onSuccess={() => {
            setIsEditUserStatusModalOpen(false);
            setEditUserData({});
          }}
          onClose={() => {
            setIsEditUserStatusModalOpen(false)
            setEditUserData({});
          }}
          editUserData={editUserData}
          api={api}
        />
      )}

    </CenterWrapper>
  );
}

export default AddTeam
