import React, { useState, useEffect } from "react";
import {
  ExclamationCircleIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";

const DeployDomainsAccordion = ({ domains, loadingDomains, saveDomains }) => {
  const [editableDomains, setEditableDomains] = useState(domains || []);
  const [isExpanded, setIsExpanded] = useState(false);
  const [message, setMessage] = useState(null);
  const [savedDomainsCount, setSavedDomainsCount] = useState(domains.length);

  useEffect(() => {
    setEditableDomains(domains || []);
    setSavedDomainsCount(domains.length);
  }, [domains]);

  const handleSave = async () => {
    try {
      await saveDomains(editableDomains);

      setSavedDomainsCount(editableDomains.length);

      setMessage({ type: "success", text: "Domains saved successfully!" });
      setTimeout(() => {
        setMessage(null);
      }, 2000);
    } catch (err) {
      const response = err.response;

      if (response.status === 400) {
        setMessage({
          type: "error",
          text: `Not saved! ${response.data.detail}`,
        });
      } else {
        setMessage({
          type: "error",
          text: "Not saved! There was an error with the provided domains.",
        });
      }
    }
  };

  const handleDomainChange = (index, value) => {
    const updatedDomains = [...editableDomains];
    updatedDomains[index] = value;
    setEditableDomains(updatedDomains);
  };

  const handleAddDomain = () => {
    setEditableDomains([...editableDomains, ""]);
  };

  const handleRemoveDomain = index => {
    const updatedDomains = editableDomains.filter((_, i) => i !== index);
    setEditableDomains(updatedDomains);
  };

  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const isValid = savedDomainsCount > 0;

  return (
    <div className="w-full mx-auto pb-4 bg-white">
      {loadingDomains ? (
        <div className="text-center py-4">Loading domains...</div>
      ) : (
        <div>
          <div
            className={`flex items-center justify-between p-4 rounded-md cursor-pointer ${
              isValid
                ? "bg-primary text-white"
                : "bg-yellow-100 text-yellow-600"
            }`}
            onClick={handleToggleExpand}
          >
            <div className="flex items-center space-x-2">
              {isValid ? (
                <InformationCircleIcon className="h-5 w-5 text-white" />
              ) : (
                <ExclamationCircleIcon className="h-5 w-5 text-yellow-600" />
              )}
              {isExpanded ? (
                <span>Domain verification</span>
              ) : (
                <span>
                  {isValid
                    ? `${savedDomainsCount} domain(s) configured`
                    : "Domain verification required"}
                </span>
              )}
            </div>
            <button className="text-primary">
              {isExpanded ? "Collapse" : "Expand"}
            </button>
          </div>

          {isExpanded && (
            <div className="mt-4 space-y-4">
              {message && (
                <div
                  className={`mt-4 p-4 rounded-md ${
                    message.type === "success"
                      ? "bg-emerald-100 text-emerald-700"
                      : "bg-red-100 text-red-700"
                  }`}
                >
                  {message.text}
                </div>
              )}
              <div className="space-y-2">
                {editableDomains.map((domain, index) => (
                  <div key={index} className="flex items-center space-x-4">
                    <input
                      type="text"
                      value={domain}
                      onChange={e => handleDomainChange(index, e.target.value)}
                      className="w-full p-2 rounded-md"
                      placeholder="Enter domain"
                    />
                    <button
                      onClick={() => handleRemoveDomain(index)}
                      className="text-primary"
                    >
                      Remove
                    </button>
                  </div>
                ))}
              </div>

              <div className="flex justify-between">
                <button
                  onClick={handleAddDomain}
                  className="text-primary hover:text-secondary p-2 rounded-md"
                >
                  Add Domain
                </button>
                <button
                  onClick={handleSave}
                  className="bg-primary text-white p-2 rounded-md hover:bg-secondary"
                >
                  Save Domains
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default DeployDomainsAccordion;
