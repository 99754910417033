import React from "react";
import logo from "../logo.png";
import { useEffect } from "react";

import { ReactComponent as BackButtonIcon } from "../imgs/icons/backButton.svg";

const WizardStep = ({
  children,
  nextEnabled = true,
  handleNextClick,
  handleSkipStepClick,
  handleBackClick,
  showBackOption = true,
}) => {
  useEffect(() => {
    const originalBackgroundColor = document.body.style.backgroundColor;

    document.body.style.backgroundColor = "#F0F0EE";

    // Cleanup function to revert the background color when the component unmounts
    return () => {
      document.body.style.backgroundColor = originalBackgroundColor;
    };
  }, []);

  return (
    <div className="flex flex-col h-screen bg-[#F0F0EE]">
      <div className="flex flex-col h-[60%]">
        <header className="min-h-[80px] w-full h-20 border-b border-gray-300 flex items-center relative cursor-pointer">
          {showBackOption && (
            <div
              className="absolute left-10 flex items-center"
              onClick={handleBackClick}
            >
              <BackButtonIcon width={35} />
            </div>
          )}
          <div className="flex items-center justify-center flex-grow">
            <img src={logo} alt="SupportMagic" className="h-auto max-h-full" />
          </div>
        </header>

        <main className="flex-grow flex flex-col items-center justify-center space-y-4">
          {children}
          <button
            onClick={handleNextClick}
            className={`flex justify-center rounded-md px-3 py-1.5 text-sm
              ${
                nextEnabled
                  ? "bg-[#30a46c] cursor-pointer hover:bg-emerald-500 focus:ring-2 focus:outline-none focus:ring-offset-2 focus:ring-green-600"
                  : "bg-gray-300 cursor-not-allowed"
              }
              font-semibold leading-6 shadow-sm text-white mb-2.5 shadow-sm w-full max-w-[300px] mx-auto`}
            disabled={!nextEnabled}
          >
            Next →
          </button>

          <button
            className="flex justify-center px-10 py-2 text-sm cursor-pointer border-none bg-none text-[#666] underline"
            onClick={handleSkipStepClick}
          >
            Skip this step
          </button>
        </main>
      </div>
    </div>
  );
};

export default WizardStep;
