import React from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";

import "./App.css";
import { AuthProvider } from "./AuthContext";
import Signup from "./onboarding/Signup";
import Callback from "./Callback";
import ProtectedRoute from "./ProtectedRoute";
import UseCaseSelection from "./onboarding/UseCaseSelection";
import EnterWebsiteURL from "./onboarding/EnterWebsiteURL";
import SelectContent from "./onboarding/SelectContent";
import GmailSupport from "./onboarding/ingestionSources/GmailSupport";
import IntercomSupport from "./onboarding/ingestionSources/IntercomSupport";
import FileUploadSupport from "./onboarding/ingestionSources/FileUploadSupport";
import SubscriptionPage from "./onboarding/SubscriptionPage";
import RecoverPasswordIntent from "./onboarding/RecoverPasswordIntent";
import PasswordResetSuccess from "./onboarding/PasswordResetSuccess";
import DashboardLayout from "./dashboard/DashboardLayout";
import AIInbox from "./dashboard/AIInbox";
import Actions from "./dashboard/Actions";
import ChatBot from "./dashboard/Setup/Chatbot";
import Branding from "./dashboard/Setup/Branding";
import Deploy from "./dashboard/Setup/Deploy";
import Profile from "./dashboard/Profile";
import KnowledgeSources from "./dashboard/Setup/KnowledgeSources/KnowledgeSources";
import Intents from "./dashboard/Setup/Intents";
import Settings from "./dashboard/Settings";
import Integrations from "./dashboard/Integrations";
import PasswordReset from "./onboarding/PasswordReset";
import RecoverPassword from "./onboarding/RecoverPassword";
import Tasks from "./dashboard/Tasks/Tasks";
import NotFound from "./NotFound";

import { IngestionWizardProvider } from "./onboarding/IngestionWizardContext";

import "antd/dist/reset.css";
import { BillingContextProvider } from "./BillingContext";
import SuccessStripe from "./dashboard/SuccessStripe";
import CancelStripe from "./dashboard/CancelStripe";

import ManageBilling from "./dashboard/settings/ManageBilling";
import Team from "./dashboard/Team/Team";
import AddTeam from "./dashboard/settings/AddTeam";
import CompanyInfo from "./dashboard/settings/CompanyInfo";

function App() {
  return (
    <AuthProvider>
      <IngestionWizardProvider>
        <Router>
          <Routes>
            {/* Public Routes */}
            <Route path="/" element={<Navigate to="/onboarding/signin" />} />
            <Route path="/password-reset" element={<PasswordReset />} />
            <Route
              path="/password-reset/success"
              element={<PasswordResetSuccess />}
            />
            <Route path="/recover-password" element={<RecoverPassword />} />
            <Route
              path="/recover-password/intent"
              element={<RecoverPasswordIntent />}
            />
            <Route path="/onboarding" element={<Signup sign_in={true} />} />
            <Route
              path="/onboarding/signin"
              element={<Signup sign_in={true} />}
            />
            <Route path="/onboarding/signup" element={<Signup />} />
            <Route path="/callback" element={<Callback />} />
            <Route
              path="/onboarding/use-case"
              element={<ProtectedRoute component={UseCaseSelection} />}
            />
            <Route
              path="/onboarding/website-url"
              element={<ProtectedRoute component={EnterWebsiteURL} />}
            />
            <Route
              path="/onboarding/select-content"
              element={<ProtectedRoute component={SelectContent} />}
            />
            <Route
              path="/onboarding/gmail-support"
              element={<ProtectedRoute component={GmailSupport} />}
            />
            <Route
              path="/onboarding/intercom-support"
              element={<ProtectedRoute component={IntercomSupport} />}
            />
            <Route
              path="/onboarding/file-upload-support"
              element={<ProtectedRoute component={FileUploadSupport} />}
            />
            <Route
              path="/onboarding/subscription"
              element={<ProtectedRoute component={SubscriptionPage} />}
            />

            {/* Protected Routes with Billing Context */}
            <Route
              path="/setup/*"
              element={
                <BillingContextProvider>
                  <ProtectedRoute component={DashboardLayout} />
                </BillingContextProvider>
              }
            >
              {/* Subroutes of Setup */}
              <Route path="knowledge-sources" element={<KnowledgeSources />} />
              <Route path="branding" element={<Branding />} />
              <Route path="intents" element={<Intents />} />
              <Route path="chatbot" element={<ChatBot />} />
              <Route path="deploy" element={<Deploy />} />
            </Route>

            <Route
              path="/settings/*"
              element={
                <BillingContextProvider>
                  <ProtectedRoute component={DashboardLayout} />
                </BillingContextProvider>
              }
            >
              {/* Subroutes of Dashboard */}
              <Route path="manage_billing" element={<ManageBilling />} />
              <Route path="team" element={<Team />} />
              <Route path="add_team" element={<AddTeam />} />
              <Route path="company_info" element={<CompanyInfo />} />
            </Route>

            <Route
              path="/dashboard/*"
              element={
                <BillingContextProvider>
                  <ProtectedRoute component={DashboardLayout} />
                </BillingContextProvider>
              }
            >
              {/* Subroutes of Dashboard */}
              <Route path="ai-inbox" element={<AIInbox />} />
              <Route path="tasks" element={<Tasks />} />
              <Route path="actions" element={<Actions />} />
              <Route path="settings" element={<Settings />} />
              <Route path="profile" element={<Profile />} />
              <Route path="integrations" element={<Integrations />} />
              <Route path="success" element={<SuccessStripe />} />
              <Route path="cancel" element={<CancelStripe />} />
            </Route>

            {/* Catch-All Route */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      </IngestionWizardProvider>
    </AuthProvider>
  );
}

export default App;
