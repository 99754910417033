import React, { useCallback, useEffect, useState } from "react";
import SetupTab from "./components/SetupTab.jsx";
import api, { WIDGET_URL } from "../../api";
import { useCompanyApi } from "../../hooks/useCompanyApi";
import CenterWrapper from "../../components/CenterWrapper.jsx";
import GeneralSetupTab from "../../components/GeneralSetupTab";
import GlobalLoadingSpinner from "../../components/GlobalSpinner.jsx";

const Chatbot = () => {
  const { company, fetchCompany } = useCompanyApi();
  const chatbotUuid = localStorage.getItem("chatbot_uuid") || "";

  const [chatSettings, setChatSettings] = useState({
    companyName: "",
    chatColor: "#7856d2",
    buttonColor: "#7856d2",
    companyImageUrl: "",
    isDataLoad: false,
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchCompany();
    setIframeData();
  }, [chatbotUuid]);

  useEffect(() => {
    if (company) {
      setChatSettings(prev => ({
        ...prev,
        companyName: company.name,
        companyImageUrl: company.picture_url,
      }));
    }
  }, [company]);

  const setIframeData = useCallback(async () => {
    if (!chatSettings.isDataLoad) {
      const response = await api.get(`/api/chatbot/${chatbotUuid}`);
      const chatbot = response.data;
      setChatSettings(prev => ({
        ...prev,
        buttonColor: `#${chatbot.button_color}`,
        chatColor: `#${chatbot.chat_color}`,
        isDataLoad: true,
      }));
      setLoading(false);
    }
  }, [chatbotUuid, chatSettings.isDataLoad]);

  const postIframeMessage = resetConversation => {
    const iframe = document.getElementById("support-magic-widget-iframe");
    if (iframe) {
      iframe.contentWindow.postMessage(
        {
          type: "SET_CUSTOM_OPTIONS",
          resetConversation: resetConversation,
          url: window.location.href,
          tenantId: chatbotUuid,
          secondaryColor: chatSettings.buttonColor,
          fontColor: chatSettings.chatColor,
          companyName: chatSettings.companyName,
          companyLogo: chatSettings.companyImageUrl,
        },
        "*"
      );
    }
  };

  useEffect(() => {
    const firstLoad = !localStorage.getItem("reset_conversation_done");

    if (chatSettings.isDataLoad && chatbotUuid) {
      postIframeMessage(firstLoad);

      if (firstLoad) {
        localStorage.setItem("reset_conversation_done", "true");
      }
    }
  }, [chatbotUuid, chatSettings, setIframeData]);

  const handleResetConversation = () => {
    postIframeMessage(true);
  };

  return (
    <CenterWrapper>
      <GlobalLoadingSpinner loading={loading} />

      <SetupTab
        title={"Test"}
        subtitle={
          "Test out your AI agent’s ability to answer your company questions and handle end to end customer support."
        }
        pageType={"setup"}
        activeTab={"chatbot"}
      />

      <div className="flex justify-center">
        <button
          onClick={handleResetConversation}
          className="px-2 py-2 mb-5 text-xs bg-secondary text-white rounded-md hover:bg-primary"
        >
          Reset Conversation
        </button>
      </div>
      <div className="min-h-screen flex justify-center">
        <iframe
          id="support-magic-widget-iframe"
          src={`${WIDGET_URL}/chat.html`}
          title="Live Chat Widget"
          style={{
            border: "none",
            width: "870px",
            height: "1000px",
            position: "relative",
            bottom: "5px",
            right: "5px",
            zIndex: 9999,
          }}
        />
      </div>
    </CenterWrapper>
  );
};

export default Chatbot;
