import React, { useCallback, useEffect, useState } from "react";
import ItemBox from "./ItemBox";
import TasksNav from "./TasksNav";
import GlobalLoadingSpinner from "../../components/GlobalSpinner";
import api from "../../api";
import ItemModal from "./ItemModal";
import { PaginationRow } from "../../components/PaginationRow";
import CenterWrapper from "../../components/CenterWrapper";

const tasksTypes = ["open", "closed", "all"];

const Tasks = () => {
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [taskType, setTaskType] = useState(tasksTypes[0]);
  const [page, setPage] = useState(1);
  const [totalTasks, setTotalTasks] = useState(0);
  const chatbotUuid = localStorage.getItem("chatbot_uuid");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [singleTask, setSingleTask] = useState({});

  const pageSize = 5;
  const totalPages = Math.ceil(totalTasks / pageSize);

  const fetchTasks = useCallback(async () => {
    if (!chatbotUuid) {
      console.error(
        "Chatbot UUID is not found in local storage or URL parameters"
      );
      return;
    }
    setLoading(true);
    setTasks([]);
    let apiFilters = {};

    switch (taskType) {
      case "closed":
        apiFilters = { resolved: true, handed_off: true };
        break;
      case "open":
        apiFilters = { resolved: false, handed_off: true };
        break;
      case "all":
        apiFilters = { handed_off: true };
        break;
      default:
        apiFilters = {};
    }

    try {
      const { data } = await api.get(`/api/conversations/${chatbotUuid}`, {
        params: { page, page_size: pageSize, ...apiFilters },
      });
      setTotalTasks(data.total_conversations);
      setTasks(data.conversations);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  });

  const handleConversation = async task => {
    try {
      await api.post("/api/update-conversation", {
        conversation_uuid: task.conversation_uuid,
        resolved: task.resolved ? false : true,
      });

      fetchTasks();
    } catch (err) {
      console.error("Failed to update conversation", err);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTasks();
  }, [taskType, page]);

  return (
    <CenterWrapper>
      <GlobalLoadingSpinner loading={loading} />
      <div>
        <div>
          <h1 className="text-lg sm:text-xl">Tasks</h1>
          <h5 className="text-sm text-gray-400 mb-8 min-h-8 max-h-20">
            Use this section to complete all tasks that have been handed off to
            your team.
          </h5>
        </div>

        <TasksNav
          setTaskType={type => {
            setPage(1);
            setTaskType(type);
          }}
          tasksTypes={tasksTypes}
          taskType={taskType}
        />

        <div
          className="flex items-center border rounded-lg px-4 py-2 mt-4 shadow-sm w-64"
          style={{ display: "none" }}
        >
          <input
            type="text"
            placeholder="Search"
            className="flex-1 focus:outline-none text-sm text-gray-700"
          />
          <button className="ml-2 w-8 h-8 flex items-center justify-center rounded-full bg-gray-100 hover:bg-gray-200">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-gray-500"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M21 21l-4.35-4.35M11 17a6 6 0 100-12 6 6 0 000 12z"
              />
            </svg>
          </button>
        </div>

        <div className="mt-6 space-y-4">
          {tasks.map(task => (
            <ItemBox
              task={task}
              key={task.conversation_id}
              setIsModalVisible={setIsModalVisible}
              setSingleTask={setSingleTask}
              handleConversation={handleConversation}
            />
          ))}
        </div>

        <hr className="my-6 border-t border-gray-300" />
        <div className="flex justify-between items-center mt-6">
          <PaginationRow
            setPage={setPage}
            totalPages={totalPages}
            currentPage={page}
          />
        </div>
      </div>
      {isModalVisible && singleTask && (
        <ItemModal
          setIsModalVisible={setIsModalVisible}
          singleTask={singleTask}
          setSingleTask={setSingleTask}
          handleConversation={handleConversation}
        />
      )}
    </CenterWrapper>
  );
};

export default Tasks;
