import React, { useState } from "react";

import { Cog8ToothIcon } from "@heroicons/react/24/outline";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";

import { useNavigate } from "react-router-dom";
import { supabase } from "../supabaseClient";

import logo from "../logo.png";

import UserProfilePopover from "./UserProfilePopover";

import { classNames } from "../utils/styles";

const SideBar = ({ navigation, user, currentPath, taskStats }) => {
  const [openItems, setOpenItems] = useState({ Setup: true });
  const toggleItem = name => {
    setOpenItems(prev => ({
      ...prev,
      [name]: !prev[name],
    }));
  };
  const navigate = useNavigate();

  const handleSignOut = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;

      localStorage.removeItem("supabase_jwt");
      localStorage.removeItem("supabase.auth.token");
      sessionStorage.removeItem("company_id");
      sessionStorage.removeItem("user_email");
      localStorage.removeItem("chatbot_uuid");
      localStorage.clear();

      navigate("/onboarding/signup");
    } catch (error) {
      console.error("Error logging out:", error.message);
    }
  };

  return (
    <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4 border border-gray-300">
      <div className="flex h-16 shrink-0 items-center">
        <img src={logo} alt="SupportMagic" className="h-8 w-auto" />
      </div>
      <nav className="flex flex-1 flex-col">
        <ul className="flex flex-1 flex-col gap-y-7">
          <li>
            <ul className="-mx-2 space-y-1">
              {navigation.map(item => (
                <li key={item.name}>
                  <a
                    href={item.href}
                    onClick={() => item.children && toggleItem(item.name)}
                    className={classNames(
                      item.aggregatorMenu
                        ? ""
                        : item.current
                          ? "bg-gray-200 text-black"
                          : "text-gray-400 hover:bg-gray-200 hover:text-black",
                      "group flex items-center gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 cursor-pointer"
                    )}
                  >
                    <item.icon
                      aria-hidden="true"
                      className="h-6 w-6 shrink-0"
                    />
                    {item.name}
                    {item.children && (
                      <span className="ml-auto">
                        {openItems[item.name] ? (
                          <ChevronUpIcon
                            aria-hidden="true"
                            className="ml-2 h-5 w-5 text-gray-400"
                          />
                        ) : (
                          <ChevronDownIcon
                            aria-hidden="true"
                            className="ml-2 h-5 w-5 text-gray-400"
                          />
                        )}
                      </span>
                    )}

                    {item.counter && (
                      <span className="ml-auto bg-gray-50 border border-gray-300 text-gray-800 text-xs font-medium px-2 py-0.5 rounded-full">
                        {taskStats.open.count}
                      </span>
                    )}
                  </a>
                  {item.children && openItems[item.name] && (
                    <ul className="ml-8 mt-1 space-y-1">
                      {item.children.map(subItem => (
                        <li key={subItem.name}>
                          <a
                            href={subItem.href}
                            className={`${currentPath === subItem.href ? "bg-gray-200 text-black" : ""} text-gray-400 hover:bg-gray-200 hover:text-black flex items-center gap-x-3 rounded-md p-2 text-sm leading-6"}`}
                          >
                            {subItem.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </li>
        </ul>

        <ul className="mb-2">
          <li className="mb-2">
            <a
              href="/settings/company_info"
              className={`${currentPath === "/settings/company_info" ? "bg-gray-200 text-black" : ""} group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-200 hover:text-black`}
            >
              <Cog8ToothIcon aria-hidden="true" className="h-6 w-6 shrink-0" />
              Settings
            </a>
          </li>
          <li className="mt-auto">
            <UserProfilePopover user={user} onSignOut={handleSignOut} />
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default SideBar;
