import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useCompanyApi } from "../../hooks/useCompanyApi";
import { CountryDropdown } from "react-country-region-selector";
import GlobalLoadingSpinner from "../../components/GlobalSpinner";
import api from "../../api.js";
import GeneralSetupTab from "../../components/GeneralSetupTab";
import CenterWrapper from "../../components/CenterWrapper";

const CompanyInfo = () => {
  const { company, fetchCompany, updateCompany, loading, setLoading } =
    useCompanyApi();
  const [feedback, setFeedback] = useState({});
  const [uploadFeedback, setUploadFeedback] = useState({});
  const [companyImageUrl, setCompanyImageUrl] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      url: "",
      address: {
        street: "",
        street_2: "",
        city: "",
        state: "",
        postal_code: "",
        country: "",
      },
    },
  });

  useEffect(() => {
    fetchCompany();
  }, []);

  useEffect(() => {
    if (company) {
      setCompanyImageUrl(company.picture_url);
      reset({
        name: company.name || "",
        url: company.url || "",
        address: {
          street: company.address?.street || "",
          street_2: company.address?.street_2 || "",
          city: company.address?.city || "",
          state: company.address?.state || "",
          postal_code: company.address?.postal_code || "",
          country: company.address?.country || "",
        },
      });
    }
  }, [company, reset]);

  const onSubmit = async data => {
    try {
      const updatedCompany = {
        name: data.name,
        url: data.url,
        address: {
          street: data.address.street,
          street_2: data.address.street_2,
          city: data.address.city,
          state: data.address.state,
          postal_code: data.address.postal_code,
          country: data.address.country,
        },
      };

      const error = await updateCompany(updatedCompany);

      if (!error) {
        setFeedback({
          status: "success",
          msg: "Company updated successfully!",
        });
      } else {
        throw error;
      }
    } catch (error) {
      setFeedback({
        status: "error",
        msg: "There was a problem updating the company!",
      });
    }

    setTimeout(() => setFeedback({}), 10000);
  };

  const uploadCompanyFile = async file => {
    // Reject if file is larger than 1MB
    const MAX_FILE_SIZE = 1 * 1024 * 1024; // 1MB in bytes
    if (file.size > MAX_FILE_SIZE) {
      setUploadFeedback({
        status: "error",
        msg: "File size exceeds 1MB limit.",
      });
      return;
    }

    setLoading(true);
    setUploadProgress(0);

    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await api.post(`/api/company/upload_picture`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: progressEvent => {
          const total = progressEvent.total;
          const current = progressEvent.loaded;
          setUploadProgress(Math.round((current / total) * 100));
        },
      });

      setCompanyImageUrl(response.data.url);
      setUploadProgress(0);
      setUploadFeedback({
        status: "success",
        msg: "Company picture uploaded!",
      });
    } catch (err) {
      setUploadFeedback({
        status: "error",
        msg: "Upload failed! Please try again later",
      });
    } finally {
      setLoading(false);
      setTimeout(() => setUploadFeedback({}), 10000);
    }
  };

  const handleFileChange = event => {
    const file = event.target.files[0];
    if (file) {
      uploadCompanyFile(file);
    }
  };

  return (
    <CenterWrapper>
      <div className="" style={{ paddingBottom: "10px" }}>
        <GlobalLoadingSpinner loading={loading} />
        <div>
          <GeneralSetupTab
            title="Account Settings"
            subtitle="Update your company settings, team, and billing."
            pageType="settings"
            activeTab="company_info"
          />

          {feedback.status && (
            <div className="sm:col-span-full">
              <div
                className={`flex items-center p-4 rounded-lg ${
                  feedback.status === "success"
                    ? "text-green-800 bg-green-50"
                    : "text-red-800 bg-red-50"
                }`}
                role="alert"
              >
                <svg
                  className="flex-shrink-0 w-4 h-4"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  {feedback.status === "success" ? (
                    <path d="M16.707 5.293a1 1 0 0 0-1.414 0L9 11.586 6.707 9.293a1 1 0 0 0-1.414 1.414l3 3a1 1 0 0 0 1.414 0l7-7a1 1 0 0 0 0-1.414z" />
                  ) : (
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm1-5a1 1 0 0 1-2 0V7a1 1 0 0 1 2 0v6zm-1-9a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3z"
                      clipRule="evenodd"
                    />
                  )}
                </svg>
                <div className="ml-3 text-sm font-medium">{feedback.msg}</div>
                <button
                  onClick={() => setFeedback({})}
                  className="ml-auto -mx-1.5 -my-1.5 rounded-lg focus:ring-2 p-1.5 inline-flex items-center justify-center h-8 w-8"
                  aria-label="Close"
                >
                  <span className="sr-only">Close</span>
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M1 1l12 12m0-12L1 13"
                    />
                  </svg>
                </button>
              </div>
            </div>
          )}

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-4 sm:px-6 md:grid-cols-3 lg:px-8">
              <div>
                <h2 className="text-base font-semibold leading-7 text-gray-900">
                  Company Information
                </h2>
                <p className="mt-1 text-sm leading-6 text-gray-500">
                  Enter your organization name and website URL that you want to appear on your invoices.
                </p>
              </div>

              <div className="md:col-span-2 bg-white p-6 rounded-lg shadow-md border border-gray-200">
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Organization Name
                  </label>
                  <input
                    type="text"
                    defaultValue="NuggMD"
                    value={company?.name}
                    className="w-full mt-1 px-3 py-2 border rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Website URL
                  </label>
                  <div className="relative mt-1">
              <span className="absolute inset-y-0 left-3 flex items-center text-gray-500">
                📧
              </span>
                    <input
                      type="text"
                      defaultValue="https://nuggmd.com"
                      value={company?.url}
                      className="w-full pl-8 pr-3 py-2 border rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                    />
                  </div>
                </div>
              </div>
            </div>

            <hr className="my-6 border-t border-gray-300" />

            <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-4 sm:px-6 md:grid-cols-3 lg:px-8">
              <div>
                <h2 className="text-base font-semibold leading-7 text-gray-900">
                  Billing Address
                </h2>
                <p className="mt-1 text-sm leading-6 text-gray-500">
                  Your billing address, this will appear on your invoices.
                </p>
              </div>

              <div className="md:col-span-2 bg-white p-6 rounded-lg shadow-md border border-gray-200">
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Street Address
                  </label>
                  <input
                    id="street"
                    {...register("address.street")}
                    type="text"
                    autoComplete="street-address"
                    className="w-full mt-1 px-3 py-2 border rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                  />
                </div>

                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Apartment, suite, etc. (optional)
                  </label>
                  <input
                    id="street_2"
                    {...register("address.street_2")}
                    type="text"
                    autoComplete="address-line2"
                    className="w-full mt-1 px-3 py-2 border rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                  />
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-3 gap-3">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 whitespace-nowrap">City</label>
                    <input
                      id="city"
                      {...register("address.city")}
                      type="text"
                      autoComplete="address-level2"
                      className="w-full mt-1 px-3 py-2 border rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                    />
                  </div>

                  <div className="col-span-1 max-[1200px]:col-span-2">
                    <label className="block text-sm font-medium text-gray-700 whitespace-nowrap">State / Province</label>
                    <input
                      id="state"
                      {...register("address.state")}
                      type="text"
                      autoComplete="address-level1"
                      className="w-full mt-1 px-3 py-2 border rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                    />
                  </div>

                  <div className="col-span-1 max-[1200px]:col-span-3">
                    <label className="block text-sm font-medium text-gray-700 whitespace-nowrap">ZIP Code / Postal Code</label>
                    <input
                      id="postal_code"
                      {...register("address.postal_code")}
                      type="text"
                      autoComplete="postal-code"
                      className="w-full mt-1 px-3 py-2 border rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                    />
                  </div>
                </div>


                <div className="mt-4">
                  <label className="block text-sm font-medium text-gray-700">Country</label>
                  <div className="mt-2">
                    <CountryDropdown
                      value={watch("address.country")}
                      onChange={val => setValue("address.country", val)}
                      className="w-full mt-1 px-3 py-2 border rounded-md bg-white focus:ring-indigo-500 focus:border-indigo-500"
                    />
                  </div>
                </div>

                <hr className="my-6 border-t border-gray-300" />

                <div className="sm:col-span-full flex items-center justify-end gap-x-3">
                  <button
                    type="button"
                    onClick={() => reset({
                      name: company?.name || "",
                      url: company?.url || "",
                      address: {
                        street: company?.address?.street || "",
                        street_2: company?.address?.street_2 || "",
                        city: company?.address?.city || "",
                        state: company?.address?.state || "",
                        postal_code: company?.address?.postal_code || "",
                        country: company?.address?.country || "",
                      },
                    })}
                    className="flex items-center px-4 py-2 border-2 border-gray-200 text-gray-700 text-xs font-medium rounded-lg hover:bg-gray-200"
                  >
                    Cancel
                  </button>

                  <button
                    type="submit"
                    className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus:ring-2 focus:ring-indigo-500"
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>

      </div>
    </CenterWrapper>
  )
};

export default CompanyInfo;
