import React from "react";
import FileUpload from "./FileUpload";

const BrandLogoUpload = ({ uploadHandler }) => {
  return (
    <FileUpload
      subtitle="SVG, PNG, JPG or GIF (max. 800x400px)"
      allowedExtensions={[
        "image/png",
        "image/jpeg",
        "image/gif",
        "image/svg+xml",
      ]}
      maxFileSizeMB={2}
      maxWidth={800}
      maxHeight={400}
      onUpload={uploadHandler}
    />
  );
};

export default BrandLogoUpload;
