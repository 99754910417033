import React from "react";

const GenericAvatar = ({ firstName = "Unknown", lastName = "User" }) => {
  const colors = [
    "Red",
    "Blue",
    "Green",
    "Yellow",
    "Orange",
    "Purple",
    "Pink",
    "Brown",
    "Black",
    "White",
    "Gray",
    "Cyan",
    "Magenta",
    "Azure",
    "Beige",
    "Coral",
    "Crimson",
    "Gold",
    "Ivory",
    "Lavender",
    "Maroon",
    "Navy",
    "Olive",
    "Peach",
    "Periwinkle",
    "Salmon",
    "Scarlet",
    "Silver",
    "Teal",
    "Turquoise",
    "Violet",
    "Amber",
    "Aqua",
    "Aquamarine",
    "Blush",
    "Bronze",
    "Charcoal",
    "Chocolate",
    "Copper",
    "Cream",
    "Emerald",
    "Fuchsia",
    "Indigo",
    "Jade",
    "Lilac",
    "Lime",
    "Mustard",
    "Onyx",
    "Pearl",
    "Plum",
    "Rose",
    "Ruby",
    "Saffron",
    "Sapphire",
    "Sepia",
    "Slate",
    "Tan",
    "Taupe",
    "Topaz",
    "Umber",
    "Verdant",
    "Wine",
    "Zinc",
    "Mint",
    "Orchid",
    "Sangria",
    "Sky Blue",
    "Ash Gray",
    "Chartreuse",
    "Cinnamon",
    "Clover",
    "Cobalt",
    "Denim",
    "Flamingo",
    "Forest Green",
    "Frost",
    "Honeydew",
    "Lilac Mist",
    "Mauve",
    "Mocha",
    "Ocher",
    "Papaya",
    "Pastel Blue",
    "Pastel Green",
    "Pastel Pink",
    "Powder Blue",
    "Royal Blue",
    "Sandy Brown",
    "Seafoam",
    "Seashell",
    "Snow",
    "Steel Blue",
    "Sunflower",
    "Tangerine",
    "Wheat",
    "Canary",
    "Cloud",
    "Driftwood",
    "Eggplant",
    "Linen",
    "Midnight Blue",
    "Mulberry",
    "Obsidian",
    "Peacock Blue",
    "Pebble",
    "Pewter",
  ];

  const colorNameToHex = colorName => {
    const colorHexMap = {
      Red: "#FF0000",
      Blue: "#0000FF",
      Green: "#008000",
      Yellow: "#FFFF00",
      Orange: "#FFA500",
      Purple: "#800080",
      Pink: "#FFC0CB",
      Brown: "#A52A2A",
      Black: "#000000",
      White: "#FFFFFF",
      Gray: "#808080",
      Cyan: "#00FFFF",
      Magenta: "#FF00FF",
      Azure: "#F0FFFF",
      Beige: "#F5F5DC",
      Coral: "#FF7F50",
      Crimson: "#DC143C",
      Gold: "#FFD700",
      Ivory: "#CD7F32", // use a darker colour
      Lavender: "#E6E6FA",
      Maroon: "#800000",
      Navy: "#000080",
      Olive: "#808000",
      Peach: "#FFDAB9",
      Periwinkle: "#CCCCFF",
      Salmon: "#FA8072",
      Scarlet: "#FF2400",
      Silver: "#C0C0C0",
      Teal: "#008080",
      Turquoise: "#40E0D0",
      Violet: "#EE82EE",
      Amber: "#FFBF00",
      Aqua: "#00FFFF",
      Aquamarine: "#7FFFD4",
      Blush: "#DE5D83",
      Bronze: "#CD7F32",
      Charcoal: "#36454F",
      Chocolate: "#D2691E",
      Copper: "#B87333",
      Cream: "#CD7F32", // use a darker colour
      Emerald: "#50C878",
      Fuchsia: "#FF00FF",
      Indigo: "#4B0082",
      Jade: "#00A86B",
      Lilac: "#C8A2C8",
      Lime: "#00FF00",
      Mustard: "#FFDB58",
      Onyx: "#353935",
      Pearl: "#EAE0C8",
      Plum: "#DDA0DD",
      Rose: "#FF007F",
      Ruby: "#E0115F",
      Saffron: "#F4C430",
      Sapphire: "#0F52BA",
      Sepia: "#704214",
      Slate: "#708090",
      Tan: "#D2B48C",
      Taupe: "#483C32",
      Topaz: "#FFC87C",
      Umber: "#635147",
      Verdant: "#4CAF50",
      Wine: "#722F37",
      Zinc: "#E9E8E7",
      Mint: "#98FF98",
      Orchid: "#DA70D6",
      Sangria: "#92000A",
      "Sky Blue": "#87CEEB",
      "Ash Gray": "#B2BEB5",
      Chartreuse: "#7FFF00",
      Cinnamon: "#D2691E",
      Clover: "#3EA055",
      Cobalt: "#0047AB",
      Denim: "#1560BD",
      Flamingo: "#FC8EAC",
      "Forest Green": "#228B22",
      Frost: "#E5FAF5",
      Honeydew: "#F0FFF0",
      "Lilac Mist": "#C3B1E1",
      Mauve: "#E0B0FF",
      Mocha: "#967969",
      Ocher: "#CC7722",
      Papaya: "#FFEFD5",
      "Pastel Blue": "#AEC6CF",
      "Pastel Green": "#77DD77",
      "Pastel Pink": "#FFD1DC",
      "Powder Blue": "#B0E0E6",
      "Royal Blue": "#4169E1",
      "Sandy Brown": "#F4A460",
      Seafoam: "#93E9BE",
      Seashell: "#FFF5EE",
      Snow: "#FFFAFA",
      "Steel Blue": "#4682B4",
      Sunflower: "#FFDA03",
      Tangerine: "#F28500",
      Wheat: "#F5DEB3",
      Canary: "#FFFF99",
      Cloud: "#C8C8C8",
      Driftwood: "#AF8751",
      Eggplant: "#614051",
      Linen: "#FAF0E6",
      "Midnight Blue": "#191970",
      Mulberry: "#C54B8C",
      Obsidian: "#282D3C",
      "Peacock Blue": "#005D8F",
      Pebble: "#8E8C84",
      Pewter: "#96A8A1",
    };
    return colorHexMap[colorName] || "#CCCCCC"; 
  };

const colour = colors.includes(firstName) ? colorNameToHex(firstName) : "#CCCCCC";

return (
  <div
    className="min-w-10 w-10 h-10 text-white text-md font-bold flex items-center justify-center rounded-full uppercase"
    style={{ backgroundColor: colour }}
  >
    {[firstName, lastName].map(word => (word ? word[0].toUpperCase() : "")).join("")}
  </div>
);
};

export default GenericAvatar;
