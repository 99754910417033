import React, { useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import RecoveryPasswordWrapper from "../components/RecoveryPasswordWrapper";

import { supabase } from "../supabaseClient";
import { LockClosedIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { ArrowLeftIcon, CheckIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";

function validatePassword(password) {
  const hasMinLength = password.length >= 8;
  const hasSpecialChar = /[^A-Za-z0-9]/.test(password);

  return {
    hasMinLength,
    hasSpecialChar,
  };
}

const PasswordReset = () => {
  const [error, setError] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const accessToken = searchParams.get("access_token");
  const validations = validatePassword(password);
  const disabled =
    !validations.hasMinLength ||
    !validations.hasSpecialChar ||
    password !== confirmPassword;

  const submit = async e => {
    try {
      e.preventDefault();

      setError("");

      await supabase.auth.setSession({
        access_token: accessToken,
        refresh_token: "<refresh_token>", // TODO: check this parameter is strictly needed (doco suggests it is)
      });

      await supabase.auth.updateUser({
        password,
      });

      navigate("/password-reset/success");
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <RecoveryPasswordWrapper>
      <form
        onSubmit={submit}
        className="items-center flex justify-center flex-col px-4 z-10"
      >
        <div className="rounded-xl border p-3 border-gray-300 bg-white">
          <LockClosedIcon className="w-8" />
        </div>

        <h2 className="text-2xl font-bold leading-9 tracking-tight text-gray-900 mt-4 text-center">
          Set new password
        </h2>
        <p className="text-center text-gray-500">
          Your new password must be different to previously used passwords.
        </p>
        <div className="w-full mt-2">
          <label
            htmlFor="password"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Password
          </label>
          <div className="mt-2">
            <input
              type="password"
              id="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
              placeholder="Enter new password"
              className="block w-full rounded-md border-0 p-2 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
            />
          </div>
        </div>

        <div className="w-full mt-4">
          <label
            htmlFor="confirmPassword"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Confirm password
          </label>
          <div className="mt-2">
            <input
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={e => setConfirmPassword(e.target.value)}
              placeholder="Confirm new password"
              className="block w-full rounded-md border-0 p-2 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
            />
          </div>
        </div>

        <div className="w-full mt-6 flex flex-col gap-4">
          <div className="flex items-center gap-2 text-gray-500 text-sm">
            <div
              className={clsx("rounded-full p-1", {
                [`bg-gray-300`]: !validations.hasMinLength,
                [`bg-[#6941C6]`]: validations.hasMinLength,
              })}
            >
              <CheckIcon className="w-3 fill-white" />
            </div>
            <span>Must be at least 8 characters</span>
          </div>

          <div className="flex items-center gap-2 text-gray-500 text-sm">
            <div
              className={clsx("rounded-full p-1", {
                [`bg-gray-300`]: !validations.hasSpecialChar,
                [`bg-[#6941C6]`]: validations.hasSpecialChar,
              })}
            >
              <CheckIcon className="w-3 fill-white" />
            </div>
            <span>Must contain one special character</span>
          </div>
        </div>

        <button
          disabled={disabled}
          type="submit"
          className="mt-6 flex w-full justify-center rounded-md bg-secondary px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Reset password
        </button>

        <Link
          to="/onboarding/signin"
          className="flex items-center gap-2 text-sm leading-6 text-gray-500 font-semibold mt-6"
        >
          <ArrowLeftIcon className="w-5" />
          <span>Back to log in</span>
        </Link>

        {error && (
          <div className="rounded-md bg-red-50 p-4 mt-6 w-full flex items-center gap-4">
            <XCircleIcon aria-hidden="true" className="size-5 text-red-400" />
            <span className="text-sm font-semibold text-red-800">{error}</span>
          </div>
        )}
      </form>
    </RecoveryPasswordWrapper>
  );
};

export default PasswordReset;
