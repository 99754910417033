import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import GlobalLoadingSpinner from "../components/GlobalSpinner";
import api from "../api.js";
import CenterWrapper from "../components/CenterWrapper.jsx";
import FileUpload from "./Setup/components/FileUpload.jsx";
import {
  EnvelopeIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";

const Profile = () => {
  const [userProfile, setUserProfile] = useState({});
  const [feedback, setFeedback] = useState({});
  const [passwordFeedback, setPasswordFeedback] = useState({});
  const [uploadFeedback, setUploadFeedback] = useState({});
  const [profileImageUrl, setProfileImageUrl] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [loading, setLoading] = useState(false);

  const fetchProfile = async () => {
    setLoading(true);

    try {
      const response = await api.get(`/api/user`);
      const userProfile = response.data;

      setUserProfile(userProfile);

      reset({
        first_name: userProfile.first_name || "",
        last_name: userProfile.last_name || "",
        email: userProfile.email || "",
      });
      setLoading(false);
    } catch (err) {
      setFeedback({
        status: "error",
        msg: JSON.stringify(err), // TODO: make friendlier
      });
      setLoading(false);
    }
  };

  const updateProfile = async data => {
    setLoading(true);
    try {
      const response = await api.put(`/api/user`, data);

      setFeedback({
        status: "success",
        msg: "Profile updated successfully",
      });

      setUserProfile(response.data);
      setLoading(false);
    } catch (err) {
      setFeedback({
        status: "error",
        msg: err.response.data.detail,
      });

      setLoading(false);
    }
  };

  const updatePassword = async data => {
    setLoading(true);
    try {
      const response = await api.put(`/api/user/password`, data);

      setPasswordFeedback({
        status: "success",
        msg: "Password updated successfully",
      });

      reset({
        password: "",
        passwordConfirmation: "",
      });

      setLoading(false);
    } catch (err) {
      setPasswordFeedback({
        status: "error",
        msg: err.response.data.detail,
      });

      setLoading(false);
    }
  };

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      first_name: "",
      last_name: "",
      password: "",
      passwordConfirmation: "",
    },
  });

  useEffect(() => {
    fetchProfile();
  }, []);

  useEffect(() => {
    if (userProfile) {
      setProfileImageUrl(userProfile.picture_url);
    }
  }, [userProfile, reset]);

  const onSubmit = async data => {
    const updatedProfile = {
      first_name: data.first_name,
      last_name: data.last_name,
    };

    updateProfile(updatedProfile);

    setTimeout(() => setFeedback({}), 10000);
  };

  const onPasswordSubmit = async data => {
    updatePassword({
      password: data.password,
    });

    setTimeout(() => setPasswordFeedback({}), 10000);
  };

  const uploadProfileFile = async file => {
    // Reject if file is larger than 1MB
    const MAX_FILE_SIZE = 1 * 1024 * 1024; // 1MB in bytes
    if (file.size > MAX_FILE_SIZE) {
      setUploadFeedback({
        status: "error",
        msg: "File size exceeds 1MB limit.",
      });
      return;
    }

    setLoading(true);
    setUploadProgress(0);

    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await api.post(`/api/upload_profile_picture`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: progressEvent => {
          const total = progressEvent.total;
          const current = progressEvent.loaded;
          setUploadProgress(Math.round((current / total) * 100));
        },
      });

      setProfileImageUrl(response.data.url);
      setUploadProgress(0);
      setUploadFeedback({
        status: "success",
        msg: "Profile picture uploaded!",
      });
    } catch (err) {
      setUploadFeedback({
        status: "error",
        msg: "Upload failed! Please try again later",
      });
    } finally {
      setLoading(false);
      setTimeout(() => setUploadFeedback({}), 10000);
    }
  };

  return (
    <CenterWrapper>
      <GlobalLoadingSpinner loading={loading} />
      <div>
        <div>
          <h1 className="text-lg sm:text-xl">Profile Settings</h1>
          <h5 className="text-sm text-gray-400 mb-8 min-h-8 max-h-20">
            Update your personal settings.
          </h5>
        </div>

        <nav className="flex space-x-1 sm:space-x-4 border-b-2 text-xs sm:text-md">
          <button className="font-semibold px-3 py-2 rounded-md text-primary border-b-2 border-primary rounded-none">
            Profile
          </button>
        </nav>

        <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-2 lg:px-8">
          <div className="max-w-[240px]">
            <h2 className="mb-0 text-base font-semibold leading-7 text-gray-900">
              Basic Information
            </h2>
            <p className="text-xs leading-6 text-gray-500">
              Update your name and profile photo. Your first name and profile
              photo will be displayed when messaging your customers.
            </p>
          </div>

          <div className="w-full">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="border p-5 rounded-xl"
            >
              {feedback.status && (
                <div className="mb-4">
                  <div
                    className={`flex items-center p-4 rounded-lg ${
                      feedback.status === "success"
                        ? "text-green-800 bg-green-50"
                        : "text-red-800 bg-red-50"
                    }`}
                    role="alert"
                  >
                    <svg
                      className="flex-shrink-0 w-4 h-4"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                    </svg>
                    <span className="sr-only">Info</span>
                    <div className="ms-3 text-sm font-medium">
                      {feedback["msg"]}
                    </div>
                    <button
                      onClick={() => setFeedback({})}
                      className={`ms-auto -mx-1.5 -my-1.5 rounded-lg focus:ring-2 p-1.5 inline-flex items-center justify-center h-8 w-8 dark:bg-gray-800 ${
                        feedback["status"] === "success"
                          ? "bg-green-50 text-green-500 hover:bg-green-200 focus:ring-green-400"
                          : "bg-red-50 text-red-500 hover:bg-red-200 focus:ring-red-400"
                      }`}
                      aria-label="Close"
                    >
                      <span className="sr-only">Close</span>
                      <svg
                        className="w-3 h-3"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 14 14"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m1 1 12 12m0-12L1 13"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              )}
              <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
                {uploadProgress > 0 && (
                  <div className="sm:col-span-6">
                    <div className="mt-4">
                      <div className="w-full bg-gray-200 rounded-full h-2">
                        <div
                          className="bg-blue-600 h-2 rounded-full"
                          style={{ width: `${uploadProgress}%` }}
                        />
                      </div>
                      <p className="text-sm text-gray-700">{`Uploading: ${uploadProgress}%`}</p>
                    </div>
                  </div>
                )}

                {uploadFeedback.status && (
                  <div className="sm:col-span-6">
                    <div
                      className={`flex items-center p-4 rounded-lg ${
                        uploadFeedback.status === "success"
                          ? "text-green-800 bg-green-50"
                          : "text-red-800 bg-red-50"
                      }`}
                      role="alert"
                    >
                      <svg
                        className="flex-shrink-0 w-4 h-4"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        {uploadFeedback.status === "success" ? (
                          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                        ) : (
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                          />
                        )}
                      </svg>
                      <span className="sr-only">Info</span>
                      <div className="ms-3 text-sm font-medium">
                        {uploadFeedback["msg"]}
                      </div>
                      <button
                        onClick={() => setUploadFeedback({})}
                        className={`ms-auto -mx-1.5 -my-1.5 rounded-lg focus:ring-2 p-1.5 inline-flex items-center justify-center h-8 w-8 dark:bg-gray-800 ${uploadFeedback["status"] === "success" ? "bg-green-50 text-green-500 hover:bg-green-200 focus:ring-green-400" : "bg-red-50 text-red-500 hover:bg-red-200 focus:ring-red-400"}`}
                        aria-label="Close"
                      >
                        <span className="sr-only">Close</span>
                        <svg
                          className="w-3 h-3"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 14 14"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m1 1 12 12m0-12L1 13"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                )}

                <div className="sm:col-span-3">
                  <label
                    htmlFor="first_name"
                    className="block text-sm font-semibold leading-6 text-gray-900"
                  >
                    First Name
                  </label>
                  <input
                    {...register("first_name", {
                      required: "First name is required",
                    })}
                    type="text"
                    id="first_name"
                    className={`mt-2 px-1 pl-3 font-semibold block w-full rounded-md border border-gray-300 bg-white py-1.5 text-gray-900 shadow-sm focus:border-primary focus:ring-primary sm:text-sm sm:leading-6 ${
                      errors.first_name ? "border-red-500" : ""
                    }`}
                  />
                  {errors.first_name && (
                    <p className="mt-2 text-sm text-red-600">
                      {errors.first_name.message}
                    </p>
                  )}
                </div>

                <div className="sm:col-span-3">
                  <label
                    htmlFor="last_name"
                    className="block text-sm font-semibold leading-6 text-gray-900"
                  >
                    Last Name
                  </label>
                  <input
                    {...register("last_name", {
                      required: "Last name is required",
                    })}
                    type="text"
                    id="last_name"
                    className={`mt-2 px-1 pl-3 font-semibold block w-full rounded-md border border-gray-300 bg-white py-1.5 text-gray-900 shadow-sm focus:border-primary focus:ring-primary sm:text-sm sm:leading-6 ${
                      errors.last_name ? "border-red-500" : ""
                    }`}
                  />
                  {errors.last_name && (
                    <p className="mt-2 text-sm text-red-600">
                      {errors.last_name.message}
                    </p>
                  )}
                </div>

                <div className="sm:col-span-6">
                  <label
                    htmlFor="email"
                    className="block text-sm font-semibold leading-6 text-gray-900"
                  >
                    Email
                  </label>

                  <div className="relative mt-1">
                    <span className="absolute inset-y-0 left-3 flex items-center text-gray-500">
                      <EnvelopeIcon aria-hidden="true" className={`h-5 w-5`} />
                    </span>

                    <input
                      {...register("email")}
                      type="email"
                      id="email"
                      readOnly
                      className="mt-2 px-1 pl-10 text-gray-500 block w-full rounded-md border border-gray-300 py-1.5 shadow-sm bg-gray-50 sm:text-sm sm:leading-6 cursor-not-allowed"
                    />
                  </div>
                </div>
              </div>

              <div className="mt-5">
                <FileUpload
                  subtitle="Allowed formats: SVG, PNG, JPG, GIF"
                  allowedExtensions={[
                    "image/png",
                    "image/jpeg",
                    "image/gif",
                    "image/svg+xml",
                  ]}
                  maxFileSizeMB={2}
                  maxWidth={800}
                  maxHeight={400}
                  onUpload={uploadProfileFile}
                  currentValue={profileImageUrl}
                />
              </div>

              <div className="mt-8 flex justify-end">
                <button
                  type="submit"
                  className="inline-block rounded-md font-semibold bg-primary px-4 py-2 text-white hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-primary text-sm"
                >
                  Save changes
                </button>
              </div>
            </form>
          </div>
        </div>

        <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-2 lg:px-8">
          <div className="max-w-[240px]">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Password
            </h2>
            <p className="text-xs leading-6 text-gray-500">
              Please enter your current password to change your password.
            </p>
          </div>

          <div className="w-full border p-5 rounded-xl">
            {true && userProfile.provider !== "email" && (
              <div className="w-full text-sm flex">
                <ExclamationCircleIcon
                  aria-hidden="true"
                  className={`h-8 w-8 text-primary mr-2`}
                />
                You signed up with your Gmail or Google Business Account.
                Resetting your password is not available.
              </div>
            )}

            {userProfile.provider === "email" ||
              (false && (
                <form
                  onSubmit={handleSubmit(onPasswordSubmit)}
                  className="md:col-span-2"
                >
                  <div>
                    <div className="w-full">
                      <label
                        htmlFor="password"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        New password
                      </label>
                      <input
                        {...register("password")}
                        type="password"
                        id="password"
                        placeholder="**************"
                        className={`pl-3 font-semibold mt-1 mb-1 px-1 block w-full rounded-md border border-gray-300 bg-white py-1.5 text-gray-900 shadow-sm focus:border-primary focus:ring-primary sm:text-sm sm:leading-6 ${
                          errors.password ? "border-red-500" : ""
                        }`}
                      />
                      <span className="text-sm">
                        Your new password must be at least 8 characters
                      </span>
                      {errors.password && (
                        <p className="mt-2 text-sm text-red-600">
                          {errors.password.message}
                        </p>
                      )}
                    </div>

                    <div className="w-full">
                      <label
                        htmlFor="passwordConfirmation"
                        className="mt-6 block text-sm font-medium leading-6 text-gray-900"
                      >
                        Confirm password
                      </label>
                      <input
                        {...register("passwordConfirmation", {
                          validate: value =>
                            value === watch("password") ||
                            "Passwords do not match",
                        })}
                        type="password"
                        placeholder="**************"
                        id="passwordConfirmation"
                        className={`pl-3 font-semibold mt-1 px-1 block w-full rounded-md border border-gray-300 bg-white py-1.5 text-gray-900 shadow-sm focus:border-primary focus:ring-primary sm:text-sm sm:leading-6 ${
                          errors.passwordConfirmation ? "border-red-500" : ""
                        }`}
                      />
                      {errors.passwordConfirmation && (
                        <p className="mt-2 text-sm text-red-600">
                          {errors.passwordConfirmation.message}
                        </p>
                      )}
                    </div>
                  </div>

                  {passwordFeedback.status && (
                    <div className="mt-4">
                      <div
                        className={`flex items-center p-4 rounded-lg ${
                          passwordFeedback.status === "success"
                            ? "text-green-800 bg-green-50"
                            : "text-red-800 bg-red-50"
                        }`}
                        role="alert"
                      >
                        <svg
                          className="flex-shrink-0 w-4 h-4"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                        </svg>
                        <span className="sr-only">Info</span>
                        <div className="ms-3 text-sm font-medium">
                          {passwordFeedback["msg"]}
                        </div>
                        <button
                          onClick={() => setPasswordFeedback({})}
                          className={`ms-auto -mx-1.5 -my-1.5 rounded-lg focus:ring-2 p-1.5 inline-flex items-center justify-center h-8 w-8 dark:bg-gray-800 ${
                            passwordFeedback["status"] === "success"
                              ? "bg-green-50 text-green-500 hover:bg-green-200 focus:ring-green-400"
                              : "bg-red-50 text-red-500 hover:bg-red-200 focus:ring-red-400"
                          }`}
                          aria-label="Close"
                        >
                          <span className="sr-only">Close</span>
                          <svg
                            className="w-3 h-3"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 14 14"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="m1 1 12 12m0-12L1 13"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  )}

                  <div className="mt-8 flex justify-end">
                    <button className="inline-block font-semibold rounded-lg mr-2 px-4 py-2 text-gray-500 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-50 border border-2 text-sm">
                      {/* TODO: Disable until there's content in the text boxes */}
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="inline-block font-semibold rounded-md bg-primary px-4 py-2 text-white hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-primary text-sm"
                    >
                      {/* TODO: Disable until there's content in the text boxes */}
                      Update password
                    </button>
                  </div>
                </form>
              ))}
          </div>
        </div>
      </div>
    </CenterWrapper>
  );
};

export default Profile;
