import React, { useState } from "react";

import { ReactComponent as TitleIcon } from "../../../imgs/icons/file.svg";
import { ReactComponent as CloseButton } from "../../../imgs/icons/buttons/close.svg";
import { ReactComponent as BackButton } from "../../../imgs/icons/buttons/back.svg";

import { CloudArrowUpIcon } from "@heroicons/react/24/outline";

const AddUploadedFiles = ({
  isOpen,
  onClose,
  goBack,
  onSuccess,
  setLoading,
  api,
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [files, setFiles] = useState([]);

  const handleDragEnter = event => {
    event.preventDefault();
    if (!isDragging) {
      setIsDragging(true);
    }
  };

  const handleDragOver = event => {
    event.preventDefault();
    if (!isDragging) {
      setIsDragging(true);
    }
  };

  const handleDragLeave = event => {
    event.preventDefault();
    setIsDragging(false);
  };

  const handleDragEnd = () => {
    setIsDragging(false);
  };

  const handleDrop = event => {
    event.preventDefault();
    setIsDragging(false);

    const droppedFiles = Array.from(event.dataTransfer.files);

    droppedFiles.forEach(file => {
      handleFileUpload(file);
    });
  };

  const handleFileSelection = event => {
    const selectedFiles = Array.from(event.target.files);

    selectedFiles.forEach(file => {
      handleFileUpload(file);
    });
  };
  const ALLOWED_EXTENSIONS = ["PDF", "XLS", "XLSX", "DOCX"];
  const MAX_FILE_SIZE = 45 * 1024 * 1024; // 45MB in bytes

  const handleFileUpload = async file => {
    const formData = new FormData();
    formData.append("file", file);

    const extension = file.name.split(".").pop().toUpperCase();

    if (!ALLOWED_EXTENSIONS.includes(extension)) {
      alert(
        `Invalid file type. Only ${ALLOWED_EXTENSIONS.join(", ")} files are allowed.`
      );
      return;
    }

    if (file.size > MAX_FILE_SIZE) {
      alert("File size exceeds the maximum limit of 45MB.");
      return;
    }

    const sizeInKB = (file.size / 1024).toFixed(2);
    const fileEntry = {
      name: file.name,
      size: `${sizeInKB} KB`,
      extension,
      progress: 0,
      error: null,
    };

    setFiles(prevFiles => [...prevFiles, fileEntry]);

    try {
      setLoading(true);

      const response = await api.post("/api/upload-file", formData, {
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: event => {
          const progress = Math.round((event.loaded * 100) / event.total);
          setFiles(prevFiles =>
            prevFiles.map(f =>
              f.name === file.name && !f.error ? { ...f, progress } : f
            )
          );
        },
      });

      setFiles(prevFiles =>
        prevFiles.map(f => (f.name === file.name ? { ...f, progress: 100 } : f))
      );
    } catch (error) {
      setFiles(prevFiles =>
        prevFiles.map(f =>
          f.name === file.name
            ? { ...f, error: "Upload failed", progress: 0 }
            : f
        )
      );
    } finally {
      setFiles(prevFiles =>
        prevFiles.map(f =>
          f.name === file.name ? { ...f, progress: f.error ? 0 : 100 } : f
        )
      );
      setLoading(false);
    }
  };

  const extensionColor = ext => {
    switch (ext.toLocaleLowerCase()) {
      case "pdf":
        return "bg-red-500";
      case "docx":
      case "doc":
        return "bg-blue-500";
      case "jpg":
      case "jpeg":
        return "bg-purple-500";
      default:
        return "bg-gray-500";
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-sm max-h-screen overflow-y-auto">
        <div className="flex justify-between items-center">
          <TitleIcon
            width={60}
            height={60}
            className="p-4 ml-6 mt-6 border rounded-xl"
          />
          <CloseButton className="mr-6 cursor-pointer" onClick={onClose} />
        </div>
        <div className="flex justify-between items-center mx-6 pt-3">
          <h2 className="text-lg font-semibold text-gray-800">Upload files</h2>
        </div>

        <div className="border-b">
          <p className="mx-6 text-xs text-gray-600">
            Upload files to train your AI Agent, images in files will be
            ignored. Accepted file types are .pdf, .xls, .xlsx, .docx.
          </p>
        </div>

        <div className="mx-6 mt-6">
          <div
            className={`bg-white border rounded-lg shadow-lg w-full max-w-sm rounded-lg p-4 text-center ${
              isDragging ? "border-primary border-2 bg-gray" : "border-gray-200"
            }`}
            onDragEnter={handleDragEnter}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            onDragLeave={handleDragLeave}
            onDragEnd={handleDragEnd}
          >
            <div>
              <CloudArrowUpIcon
                aria-hidden="true"
                className="h-8 w-8 mx-auto text-gray-700 mb-2 p-1 m-1 rounded border shadow"
              />
            </div>
            <input
              id="fileUploadInput"
              type="file"
              multiple
              accept=".pdf, .xls, .xlsx, .docx"
              className="hidden"
              onChange={handleFileSelection}
            />
            <button
              type="button"
              className="text-primary font-medium text-sm hover:underline"
              onClick={() => document.getElementById("fileUploadInput").click()}
              htmlFor="logo"
            >
              Click to upload
            </button>
            <span className="text-sm text-gray-500"> or drag and drop</span>
            <p className="mt-2 text-xs text-gray-400">
              PDF, XLS, XLSX or DOCX (max. 45mb)
            </p>
          </div>
        </div>

        <div className="mx-6 mt-6 text-xs">
          {files.map((file, index) => (
            <div
              key={index}
              className="flex items-center justify-between p-2 border rounded-lg mb-2"
            >
              <div className="relative flex items-center justify-center w-12 h-12 rounded-lg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="white"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="lightgray"
                  className="w-full h-full"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                  />
                </svg>
                <span
                  className={`absolute bottom-2 left-[-5px] px-1 py-0.5 ${extensionColor(file.extension)} text-white font-bold text-xs rounded-md`}
                >
                  {file.extension}
                </span>
              </div>

              <div className="flex-1 mx-4">
                <div className="font-semibold">{file.name}</div>
                <div className="text-gray-500">{file.size}</div>

                {!file.error && file.progress < 100 && (
                  <div className="mt-2 w-full bg-gray-200 rounded-lg h-2">
                    <div
                      className="bg-primary h-2 rounded-lg"
                      style={{ width: `${file.progress || 0}%` }}
                    />
                  </div>
                )}
              </div>

              <div className="text-right">
                {file.error ? (
                  <span className="text-red-500">{file.error}</span>
                ) : (
                  <span className="text-gray-500">{file.progress}%</span>
                )}
              </div>
            </div>
          ))}
        </div>

        <div className="flex items-center py-3">
          <button
            onClick={() => {
              setFiles([]);
              goBack();
              onSuccess();
            }}
            className="flex items-center justify-center w-1/2 text-md font-bold text-gray-500 hover:bg-gray-100 border shadow-md ml-6 mr-1 py-3 rounded-md"
          >
            <BackButton className="mr-2" />
            Go Back
          </button>
          <button
            onClick={() => {
              setFiles([]);
              onSuccess();
              onClose();
            }}
            className={`flex items-center justify-center w-1/2 text-md leading-5 bg-secondary border border-primary shadow-md hover:opacity-75 font-bold text-white ml-1 mr-6 py-3 rounded-md`}
          >
            Finish
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddUploadedFiles;
