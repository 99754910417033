import React from "react";
import { Link, useNavigate } from "react-router-dom";

import RecoveryPasswordWrapper from "../components/RecoveryPasswordWrapper";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { ArrowLeftIcon } from "@heroicons/react/20/solid";

const PasswordResetSuccess = () => {
  const navigate = useNavigate();

  const submit = async e => {
    e.preventDefault();
    navigate("/dashboard");
  };

  return (
    <RecoveryPasswordWrapper>
      <div className="items-center flex justify-center flex-col px-4 z-10">
        <div className="rounded-xl border p-3 border-gray-300 bg-white">
          <CheckCircleIcon className="w-8" />
        </div>

        <h2 className="text-2xl font-bold leading-9 tracking-tight text-gray-900 mt-4 text-center">
          Password reset
        </h2>

        <p className="text-center text-gray-500">
          Your password has been successfully reset. Click below to log in
          magically.
        </p>

        <button
          onClick={submit}
          type="button"
          className="mt-6 flex w-full justify-center rounded-md bg-secondary px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Continue
        </button>

        <Link
          to="/onboarding/signin"
          className="flex items-center gap-2 text-sm leading-6 text-gray-500 font-semibold mt-6"
        >
          <ArrowLeftIcon className="w-5" />
          <span>Back to log in</span>
        </Link>
      </div>
    </RecoveryPasswordWrapper>
  );
};

export default PasswordResetSuccess;
