import axios from 'axios'

export const WIDGET_URL = process.env.REACT_APP_WIDGET_BASE_URL;

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

api.interceptors.request.use(config => {
    const token = sessionStorage.getItem('supabase_jwt');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

export default api;
