import React, { useEffect, useState } from "react";
import { ReactComponent as CheckIcon } from "../../imgs/icons/check.svg";
import { ReactComponent as DeleteIcon } from "../../imgs/icons/trash.svg";
import { ReactComponent as CloseButton } from "../../imgs/icons/buttons/close.svg";
import api from "../../api";

const TeamMemberStatusUpdateModal = ({ isOpen, onClose, editUserData, onSuccess, setLoading, fetchTeam, api}) => {
  const [userObj, setUserObj] = useState({});

  useEffect(() => {
    setUserObj(editUserData);
  },[]);

  const handleUpdateStatus = async () => {
    try {
      setLoading(true);
      await api.put(`/api/team/user/${userObj.id}`, { active: !userObj.active });
      await fetchTeam();
    } finally {
      setLoading(false);
      onSuccess()
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-lg overflow-y-auto max-h-screen">
        <div className="flex justify-between items-center">
          {userObj.active ?
              <DeleteIcon
                width={60}
                height={60}
                className="p-4 ml-6 mt-6 bg-red-100 text-red-600 rounded-full font-bold"
              />
            :
            <CheckIcon
              width={60}
              height={60}
              className="p-4 ml-6 mt-6 bg-green-100 text-green-600 rounded-full font-bold"
            />
          }
          <CloseButton className="mr-6 cursor-pointer" onClick={onClose} />
        </div>
        <div className="flex justify-between items-center mx-6 pt-3">
          {userObj.active ?
            <h2 className="text-lg font-semibold text-gray-800">Deactivate team member</h2>
            :
            <h2 className="text-lg font-semibold text-gray-800">Reactivate team member</h2>
          }
        </div>
        <div className="border-b">
          {userObj.active ?
            <p className="mx-6 text-1xl text-gray-600">
              Are you sure you want to deactivate {userObj.email}? They will lose access to there account.
            </p>
            :
            <p className="mx-6 text-1xl text-gray-600">
              Are you sure you want to reactive {userObj.email}? They will regain access to there account.
            </p>
          }
        </div>

        <div className="flex items-center py-3 border-t">
          <button
            onClick={onClose}
            className="w-1/2 text-md font-bold text-gray-500 hover:bg-gray-100 border shadow-md ml-6 mr-1 py-3 rounded-md"
          >
            Cancel
          </button>
          <button
            onClick={handleUpdateStatus}
            className={`w-1/2 text-md border shadow-md font-bold text-white ml-1 mr-6 py-3 rounded-md ${
              userObj.active
                ? "bg-red-600 border-red-700 hover:bg-red-700 hover:opacity-90"
                : "bg-secondary border-primary hover:opacity-75"
            }`}
          >
            {userObj.active ? "Deactivate" : "Confirm"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default TeamMemberStatusUpdateModal;
