import React from "react";

const CenterWrapper = ({ children }) => {
  return (
    <div className="flex justify-center mx-16">
      <div
        className="w-full mt-12 mx-auto max-w-[1020px]"
        style={{ paddingBottom: "10px" }}
      >
        {children}
      </div>
    </div>
  );
};

export default CenterWrapper;
