import React, { useState } from "react";
import { ReactComponent as TitleIcon } from "../../imgs/icons/userPlus.svg";
import { ReactComponent as CloseButton } from "../../imgs/icons/buttons/close.svg";

const initUserObj = { email: "", role: "", error: "" };
const AddTeamsModal = ({ isOpen, onClose, onSuccess, fetchTeam, setLoading, api }) => {
  const [user, setUser] = useState([initUserObj]);

  if (!isOpen) return null;

  // Email Validation Function
  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  // Handle Input Change
  const handleInputChange = (index, field, value) => {
    setUser((prevUsers) =>
      prevUsers.map((userObj, i) =>
        i === index ? { ...userObj, [field]: value, error: "" } : userObj
      )
    );
  };

  // Add New User
  const addMultiUser = () => {
    setUser((prevObj) => [...prevObj, initUserObj]);
  };

  const clearUser = (index) => {
    setUser((prevObj) => prevObj.filter((_, i) => i !== index));
  };

  const createUser = async () => {
    setLoading(true);
    let isValid = true;

    let updatedUsers = user.map((userObj) => {
      if (!validateEmail(userObj.email)) {
        isValid = false;
        return { ...userObj, error: "Invalid email address" };
      }
      return userObj;
    });

    if (!isValid) {
      setUser(updatedUsers);
      setLoading(false);
      return;
    }

    try {
      const { data } = await api.post("/api/team/add-member", user);

      updatedUsers = user.map((u) => {
        const responseEntry = data.find((res) => res.email === u.email);
        return responseEntry ? { ...u, error: responseEntry.error || "" } : u;
      });

      const failedUsers = updatedUsers.filter((u) => u.error);

      setUser(failedUsers);

      if (failedUsers.length === 0) {
        onSuccess();
        onClose();
      }
    } catch (e) {
      console.log("Error:", e);
    } finally {
      fetchTeam();
      setLoading(false);
    }
  };


  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-lg overflow-y-auto max-h-screen">
        <div className="flex justify-between items-center">
          <TitleIcon width={60} height={60} className="p-4 ml-6 mt-6 border rounded-xl" />
          <CloseButton className="mr-6 cursor-pointer" onClick={onClose} />
        </div>
        <div className="flex justify-between items-center mx-6 pt-3">
          <h2 className="text-lg font-semibold text-gray-800">Invite Team Members</h2>
        </div>

        <div className="border-b">
          <p className="mx-6 text-xs text-gray-600">Invite your team members to manage your support</p>
        </div>

        <div className="border-t border-1 border-red">
          <div key="teams" className="mb-4 mx-6">
            <div className="mt-4 space-y-4">
              {user.map((userObj, index) => (
                <div key={index} className="flex items-center justify-between space-x-2">
                  <div className="flex items-center space-x-2">
                    {/* Email Input */}
                    <div className="flex-1">
                      <label className="text-sm text-gray-600">Email address</label>
                      <input
                        type="email"
                        value={userObj.email}
                        onChange={(e) => handleInputChange(index, "email", e.target.value)}
                        className={`w-full px-3 py-2 border rounded-lg focus:ring ${
                          userObj.error ? "border-red-500" : "focus:ring-indigo-200"
                        }`}
                        placeholder="you@untitledui.com"
                      />
                      {userObj.error && <p className="absolute left-0 text-red-500 text-xs mt-1">{userObj.error}</p>}
                    </div>

                    {/* Role Dropdown */}
                    <div className="w-28">
                      <label className="text-sm text-gray-600">Role</label>
                      <select
                        className="w-full px-3 py-2 border rounded-lg bg-white focus:ring focus:ring-indigo-200"
                        value={user[index].role || "admin"}
                        onChange={(e) => handleInputChange(index, "role", e.target.value)}
                      >
                        <option value="admin">Admin</option>
                        <option value="member">Member</option>
                      </select>
                    </div>


                    {/* Remove Button */}
                    <div className="w-8 flex flex-col items-center">
                      <label className="text-sm text-gray-600 invisible">X</label>
                      {index > 0 && (
                        <button className="text-red-500 hover:text-red-700 text-xs" onClick={() => clearUser(index)}>
                          ❌
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ))}
              <button onClick={addMultiUser} className="text-indigo-600 text-sm mt-3 flex items-center">
                + Add another
              </button>
            </div>
          </div>
        </div>

        <div className="flex items-center py-3 border-t">
          <button
            onClick={onClose}
            className="flex items-center justify-center w-1/2 text-md font-bold text-gray-500 hover:bg-gray-100 border shadow-md ml-6 mr-1 py-3 rounded-md"
          >
            Cancel
          </button>
          <button
            className="flex items-center justify-center w-1/2 text-md leading-5 bg-secondary border border-primary shadow-md hover:opacity-75 font-bold text-white ml-1 mr-6 py-3 rounded-md"
            onClick={createUser}
          >
            Send Invites
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddTeamsModal;
