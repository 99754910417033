import React, { useState } from "react";
import { ReactComponent as CloseButton } from "../../../imgs/icons/buttons/close.svg";
import { ReactComponent as HelpIcon } from "../../../imgs/icons/help.svg";

import { ReactComponent as GmailIcon } from "../../../imgs/icons/gmail.svg";
import { ReactComponent as IntercomIcon } from "../../../imgs/icons/intercom.svg";
import { ReactComponent as UploadFileIcon } from "../../../imgs/icons/file-upload.svg";
import { ReactComponent as GlobeIcon } from "../../../imgs/icons/globe-02.svg";
import { ReactComponent as FileIcon } from "../../../imgs/icons/file.svg";
import { ReactComponent as BulletsIcon } from "../../../imgs/icons/bullets.svg";

function AddSourceModal({ onClose, steps }) {
  const [selectedOption, setSelectedOption] = useState("");

  const options = [
    {
      value: "website",
      label: "Website URL(s)",
      description: "One or many",
      image: <GlobeIcon className="w-16" />,
      imageSm: <GlobeIcon className="w-6 h-6" />,
    },
    {
      value: "files",
      label: "Upload Files",
      description: ".pdf, .xlsx, .docx",
      image: <UploadFileIcon className="w-16" />,
      imageSm: <UploadFileIcon className="w-6 h-6" />,
    },
    // {
    //   value: "custom",
    //   label: "Custom Answer",
    //   description: "Q&A style answers",
    //   image: <FileIcon className="w-16" />,
    //   imageSm: <FileIcon className="w-6 h-6" />,
    // },
    // {
    //   value: "policies",
    //   label: "Business Policies",
    //   description: "Shipping, refund, exchange, etc.",
    //   image: <BulletsIcon className="w-16" />,
    //   imageSm: <BulletsIcon className="w-6 h-6" />,
    // },
    {
      value: "gmail",
      label: "Gmail Conversations",
      description: "Upload conversations to train",
      image: <GmailIcon className="w-16" />,
      imageSm: <GmailIcon className="w-6 h-6" />,
    },
    {
      value: "intercom",
      label: "Intercom Conversations",
      description: "Upload conversations to train",
      image: <IntercomIcon className="w-16" />,
      imageSm: <IntercomIcon className="w-6 h-6" />,
    },
  ];

  const goToNextStep = () => {
    if (steps[selectedOption]) {
      steps[selectedOption]();
      onClose();
    } else {
      alert("Coming soon");
    }
  };

  const actionDisabled = !selectedOption;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center">
      <div className="bg-white rounded-lg shadow-lg max-w-sm md:max-w-xl w-full p-6 max-h-screen overflow-y-auto">
        <div className="flex justify-between items-center">
          <h2 className="text-lg font-semibold text-gray-900">Add Source</h2>
          <CloseButton className="cursor-pointer" onClick={onClose} />
        </div>
        <p className="text-sm text-gray-600 mb-6">
          Select a source to add new knowledge for your AI Support Agent.
        </p>

        <div className="hidden md:block">
          <div className="grid grid-cols-2 gap-6">
            {options.map(option => (
              <label
                key={option.value}
                className="flex flex-col items-start cursor-pointer"
                onClick={() => setSelectedOption(option.value)}
              >
                <div
                  className={`w-full h-40 rounded-lg p-4 text-center border-2 ${
                    selectedOption === option.value
                      ? "border-[#9E77ED]"
                      : "border-gray-200"
                  }`}
                >
                  <div className="h-full flex items-center justify-center">
                    <div className="rounded-lg bg-white flex justify-center items-center w-24 h-24 mx-auto border-2">
                      {option.image}
                    </div>
                  </div>
                </div>
                <div className="flex justify-between items-start w-full mt-2 bg-white">
                  <div>
                    <h3 className="text-sm font-medium text-gray-800 mb-0">
                      {option.label}
                    </h3>
                    <p className="text-gray-600 text-sm">
                      {option.description}
                    </p>
                  </div>
                  {selectedOption === option.value ? (
                    <label class="relative flex items-center cursor-pointer">
                      <input type="radio" name="radio" class="peer hidden" />
                      <div class="w-6 h-6 rounded-full border-8 border-[#9E77ED] flex items-center justify-center">
                        <div class="w-2 h-2 bg-white rounded-full"></div>
                      </div>
                    </label>
                  ) : (
                    <label class="relative flex items-center cursor-pointer">
                      <input type="radio" name="radio" class="peer hidden" />
                      <div class="w-6 h-6 rounded-full border-2 border-gray-300 flex items-center justify-center">
                        <div class="w-2 h-2 bg-white rounded-full"></div>
                      </div>
                    </label>
                  )}
                </div>
              </label>
            ))}
          </div>

          <div className="flex justify-between items-center mt-6">
            <button className="flex justify-center text-sm text-gray-500 font-bold">
              {/* <HelpIcon className="w-5 h-5" />
              <span className="ml-1">Need help?</span> */}
            </button>

            <div className="flex space-x-4">
              <button
                onClick={onClose}
                className="px-5 py-3 font-bold text-gray-500 hover:bg-gray-100 border shadow-md rounded-md"
              >
                Cancel
              </button>
              <button
                onClick={goToNextStep}
                className={`px-5 py-3 bg-secondary border border-primary shadow-md ${actionDisabled ? "cursor-not-allowed" : "hover:opacity-75"} font-bold text-white ml-1 mr-6 py-3 rounded-md`}
                disabled={actionDisabled}
              >
                Next
              </button>
            </div>
          </div>
        </div>

        <div className="block md:hidden">
          <div className="grid grid-cols-1 gap-4">
            {options.map(option => (
              <div
                key={option.value}
                className="flex items-start space-x-2 min-h-20 max-h-20"
                onClick={() => setSelectedOption(option.value)}
              >
                <div
                  className={`w-1/3 rounded-lg p-4 text-center cursor-pointer border-2 b-gray-500 bg-gray-100 ${
                    selectedOption === option.value
                      ? "border-primary border-[#9E77ED]"
                      : "border-gray-200"
                  }`}
                >
                  <div className="h-full flex items-center justify-center">
                    <div className="rounded-lg bg-white flex justify-center items-center w-12 h-12 mx-auto border-2 b-gray-500">
                      {option.imageSm}
                    </div>
                  </div>
                </div>

                <div className="w-2/3 bg-white flex justify-between items-start px-4 py-2 rounded-md">
                  <div>
                    <h3 className="text-sm font-medium text-gray-800 mb-1">
                      {option.label}
                    </h3>
                    <p className="text-gray-600 text-sm">
                      {option.description}
                    </p>
                  </div>
                  <div className="mt-0 flex justify-end">
                    {selectedOption === option.value ? (
                      <label class="relative flex items-center cursor-pointer">
                        <input type="radio" name="radio" class="peer hidden" />
                        <div class="w-6 h-6 rounded-full border-8 border-[#9E77ED] flex items-center justify-center">
                          <div class="w-2 h-2 bg-white rounded-full"></div>
                        </div>
                      </label>
                    ) : (
                      <label class="relative flex items-center cursor-pointer">
                        <input type="radio" name="radio" class="peer hidden" />
                        <div class="w-6 h-6 rounded-full border-2 border-gray-300 flex items-center justify-center">
                          <div class="w-2 h-2 bg-white rounded-full"></div>
                        </div>
                      </label>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="flex justify-between items-center mt-6">
            <button
              className="flex justify-center text-sm text-gray-500 font-bold"
              onClick={() => alert("Unimplemented")}
            >
              <HelpIcon className="w-5 h-5" />
              <span className="ml-1">Need help?</span>
            </button>

            <div className="flex space-x-4">
              <button
                onClick={onClose}
                className="px-5 py-3 font-bold text-gray-500 hover:bg-gray-100 border shadow-md rounded-md"
              >
                Cancel
              </button>
              <button
                onClick={goToNextStep}
                className={`px-5 py-3 bg-secondary border border-primary shadow-md ${actionDisabled ? "cursor-not-allowed" : "hover:opacity-75"} font-bold text-white ml-1 mr-6 py-3 rounded-md`}
                disabled={actionDisabled}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddSourceModal;
