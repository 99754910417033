import React, { useState, useEffect, useRef } from "react";
import api, { WIDGET_URL } from "../../api.js";
import { useCompanyApi } from "../../hooks/useCompanyApi.js";

// import OptionSelect from "./components/OptionSelect";
import SetupTab from "./components/SetupTab.jsx";
import BrandLogoUpload from "./components/BrandLogoUpload.jsx";
import ColorPicker from "./components/ColorPicker.jsx";

import GlobalLoadingSpinner from "../../components/GlobalSpinner.jsx";

import { Transition } from "@headlessui/react";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/20/solid";
import CenterWrapper from "../../components/CenterWrapper.jsx";
import GeneralSetupTab from "../../components/GeneralSetupTab.jsx";

const Branding = () => {
  const chatbotUuid = localStorage.getItem("chatbot_uuid") || "";

  const { company, fetchCompany, updateCompany, loading, setLoading } =
    useCompanyApi();

  const [greeting, setGreeting] = useState("");
  const [prompts, setPrompts] = useState("");

  const fetchSettings = async () => {
    try {
      const response = await api.get(`/api/chatbot/${chatbotUuid}`);
      const chatbot = response.data;

      setGreeting(chatbot.greeting);
      setPrompts(chatbot.prompt_text || "");
      setAiEmployeeName(chatbot.name);
      setButtonColor(`#${chatbot.button_color || "#7856d2"}`);
      setChatButtonColor(`#${chatbot.chat_color || "#7856d2"}`);
    } catch (err) {
      console.error("Failed to fetch settings", err);
    }
  };

  useEffect(() => {
    if (!chatbotUuid) {
      console.error(
        "Chatbot UUID is not found in local storage or URL parameters"
      );
      return;
    }

    const chatbotResponse = api
      .get(`/api/chatbot/${chatbotUuid}`)
      .then(response => {
        setChatbotPictureUrl(response.data.picture_url);
      });

    fetchCompany();
    fetchSettings();
  }, [chatbotUuid]);

  useEffect(() => {
    if (company) {
      setCompanyName(company.name);
      setCompanyImageUrl(company.picture_url);
    }
  }, [company]);

  const [companyImageUrl, setCompanyImageUrl] = useState("");
  const [chatbotPictureUrl, setChatbotPictureUrl] = useState("");

  const uploadChatbotFile = async file => {
    // Reject if file is larger than 1MB
    const MAX_FILE_SIZE = 1 * 1024 * 1024; // 1MB in bytes

    if (file.size > MAX_FILE_SIZE) {
      setFeedback({
        visible: true,
        status: "error",
        msg: "Upload failed",
        submsg: "File size exceeds 1MB limit.",
      });
      return;
    }

    setLoading(true);
    // setUploadProgress(0);

    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await api.post(
        `/api/chatbot/${chatbotUuid}/upload_picture`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: progressEvent => {
            const total = progressEvent.total;
            const current = progressEvent.loaded;
            // setUploadProgress(Math.round((current / total) * 100));
          },
        }
      );

      setChatbotPictureUrl(response.data.url);
      setFeedback({
        visible: true,
        status: "ok",
        msg: "Upload completed",
        submsg: "Company picture uploaded!",
      });
      // setUploadProgress(0);
    } catch (err) {
      setFeedback({
        visible: true,
        status: "error",
        msg: "Upload failed",
        submsg: "Please try again later.",
      });
    } finally {
      setLoading(false);
      setTimeout(() => setFeedback({ visible: false }), 10000);
    }
  };

  const uploadCompanyFile = async file => {
    // Reject if file is larger than 1MB
    const MAX_FILE_SIZE = 1 * 1024 * 1024; // 1MB in bytes
    if (file.size > MAX_FILE_SIZE) {
      setFeedback({
        visible: true,
        status: "error",
        msg: "Upload failed",
        submsg: "File size exceeds 1MB limit.",
      });
      return;
    }

    setLoading(true);
    // setUploadProgress(0);

    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await api.post(`/api/company/upload_picture`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: progressEvent => {
          const total = progressEvent.total;
          const current = progressEvent.loaded;
          // setUploadProgress(Math.round((current / total) * 100));
        },
      });

      setCompanyImageUrl(response.data.url);
      setFeedback({
        visible: true,
        status: "ok",
        msg: "Upload completed",
        submsg: "Company picture uploaded!",
      });
      // setUploadProgress(0);
    } catch (err) {
      setFeedback({
        visible: true,
        status: "error",
        msg: "Upload failed",
        submsg: "Please try again later.",
      });
    } finally {
      setLoading(false);
      setTimeout(() => setFeedback({ visible: false }), 10000);
    }
  };

  const [companyName, setCompanyName] = useState("");
  const [aiEmployeeName, setAiEmployeeName] = useState("");
  const [chatButtonColor, setChatButtonColor] = useState(undefined);
  const [buttonColor, setButtonColor] = useState(undefined);

  const handleChatButtonColorChanged = async newColor => {
    setChatButtonColor(newColor);

    try {
      await api.put(`/api/chatbot/${chatbotUuid}`, {
        chat_color: newColor.substring(1),
      });

      setFeedback({
        visible: true,
        status: "ok",
        msg: "Successfully saved",
        submsg: "Changes are saved automatically.",
      });
    } catch (err) {
      setFeedback({
        visible: true,
        status: "error",
        msg: "Save failed",
        submsg: "Please try again later!",
      });
    }
  };

  const handleButtonColorChanged = async newColor => {
    setButtonColor(newColor);

    try {
      await api.put(`/api/chatbot/${chatbotUuid}`, {
        button_color: newColor.substring(1),
      });

      setFeedback({
        visible: true,
        status: "ok",
        msg: "Successfully saved",
        submsg: "Changes are saved automatically.",
      });
    } catch (err) {
      setFeedback({
        visible: true,
        status: "error",
        msg: "Save failed",
        submsg: "Please try again later!",
      });
    }
  };

  const debounceTimeout = useRef(null);

  const savePrompts = async newPromptsValue => {
    try {
      await api.post(`/api/prompts/${chatbotUuid}`, { text: newPromptsValue });
      setFeedback({
        visible: true,
        status: "ok",
        msg: "Successfully saved",
        submsg: "Changes are saved automatically.",
      });
    } catch (err) {
      setFeedback({
        visible: true,
        status: "error",
        msg: "Save failed",
        submsg: "Please try again later!",
      });
    }
  };

  const handleBrandVoiceChanged = e => {
    const value = e.target.value;
    setPrompts(value);

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => savePrompts(value), 600);
  };

  const saveGreeting = async newGreetingValue => {
    try {
      await api.put(`/api/chatbot/${chatbotUuid}/greeting`, {
        greeting: newGreetingValue,
      });

      setFeedback({
        visible: true,
        status: "ok",
        msg: "Successfully saved",
        submsg: "Changes are saved automatically.",
      });
    } catch (err) {
      setFeedback({
        visible: true,
        status: "error",
        msg: "Save failed",
        submsg: "Please try again later!",
      });
    }
  };

  const handleIntroMesageChanged = e => {
    const value = e.target.value;
    setGreeting(value);

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => saveGreeting(value), 600);
  };

  const abortIfEmptyValue = value => {
    if (value === "") {
      setFeedback({
        visible: true,
        status: "error",
        msg: "Save failed",
        submsg: "Field cannot be empty!",
      });

      return true;
    }

    return false;
  };
  const handleAiEmployeeNameChanged = e => {
    const value = e.target.value;

    if (abortIfEmptyValue(value)) {
      return;
    }

    setAiEmployeeName(value);

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => saveChatbotName(value), 600);
  };

  const handleCompanyChanged = e => {
    const value = e.target.value;

    if (abortIfEmptyValue(value)) {
      return;
    }

    setCompanyName(value);

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => saveCompanyName(value), 600);
  };

  const saveChatbotName = async value => {
    try {
      await api.put(`/api/chatbot/${chatbotUuid}`, {
        name: value,
      });

      setFeedback({
        visible: true,
        status: "ok",
        msg: "Successfully saved",
        submsg: "Changes are saved automatically.",
      });
    } catch (err) {
      setFeedback({
        visible: true,
        status: "error",
        msg: "Save failed",
        submsg: "Please try again later!",
      });
    }
  };

  const saveCompanyName = value => {
    updateCompany({ name: value }, true).then(err => {
      if (err) {
        setFeedback({
          visible: true,
          status: "error",
          msg: "Save failed",
          submsg: "Please try again later!",
        });
        return;
      }
      setFeedback({
        visible: true,
        status: "ok",
        msg: "Company name updated",
        submsg: "Changes saved automatically.",
      });
    });

    setTimeout(() => {
      setFeedback({ visible: false });
    }, 10000);
  };

  // const brandVoiceOptions = [
  //   { value: "standard", label: "Standard", icon: "check" },
  //   { value: "professional", label: "Professional", icon: "user" },
  //   { value: "humorous", label: "Humorous", icon: "smile" },
  //   { value: "custom", label: "Custom", icon: "plus" },
  // ];

  // const answerLengthOptions = [
  //   { value: "brief", label: "Brief", icon: "check" },
  //   { value: "standard", label: "Standard", icon: "user" },
  //   { value: "detailed", label: "Detailed", icon: "smile" },
  // ];

  const [feedback, setFeedback] = useState({ visible: false });

  useEffect(() => {
    const isFirstLoad = !localStorage.getItem("reset_conversation_done");

    if (chatbotUuid && buttonColor && chatButtonColor) {
      const iframe = document.getElementById("support-magic-widget-iframe");
      if (iframe) {
        iframe.onload = () => {
          iframe.contentWindow.postMessage(
            {
              type: "SET_CUSTOM_OPTIONS",
              resetConversation: isFirstLoad,
              url: window.location.href,
              tenantId: chatbotUuid,
              secondaryColor: buttonColor,
              fontColor: chatButtonColor,
              companyName: companyName,
              companyLogo: companyImageUrl,
            },
            "*"
          );

          if (isFirstLoad) {
            localStorage.setItem("reset_conversation_done", "true");
          }
        };
      }
      iframe.src = iframe.src;
    }
  }, [chatbotUuid, chatButtonColor, buttonColor, companyName, companyImageUrl]);

  const launcherStyle = {
    width: "64px",
    height: "64px",
    borderRadius: "50%",
    background: chatButtonColor,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    zIndex: 10002,
    fontSize: "24px",
    color: "white",
    position: "absolute",
    right: "30px",
    top: "100%",
    marginTop: "10px",
  };

  // TODO: convert ^ into tailwindcss

  return (
    <CenterWrapper>
      <GlobalLoadingSpinner loading={loading} />
      <GeneralSetupTab
        title={"Branding"}
        subtitle={
          "Control your brand voice and livechat branding settings within this section"
        }
        pageType={"setup"}
        activeTab={"branding"}
      />

      <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-0 flex items-end px-4 sm:items-start sm:p-6"
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          <Transition show={feedback.visible}>
            <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black/5 transition data-[closed]:data-[enter]:translate-y-2 data-[enter]:transform data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-100 data-[enter]:ease-out data-[leave]:ease-in data-[closed]:data-[enter]:sm:translate-x-2 data-[closed]:data-[enter]:sm:translate-y-0">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="shrink-0">
                    {feedback.status === "ok" && (
                      <CheckCircleIcon
                        aria-hidden="true"
                        className="size-6 text-green-400"
                      />
                    )}
                    {feedback.status === "error" && (
                      <XCircleIcon
                        aria-hidden="true"
                        className="size-6 text-red-400"
                      />
                    )}
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="mt-1 text-sm text-gray-500">{feedback.msg}</p>
                    <p className="text-sm font-medium text-gray-900">
                      {feedback.submsg}
                    </p>
                  </div>
                  <div className="ml-4 flex shrink-0">
                    <button
                      type="button"
                      onClick={() => {
                        setFeedback({ visible: false });
                      }}
                      className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon aria-hidden="true" className="size-5" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>

      <div className="min-h-screen flex flex-col lg:flex-row p-0 m-0 pb-20">
        <div className="mx-auto 3xl:mx-0 w-3/4 3xl:w-1/2 p-0 min-w-[200px]">
          <div className="space-y-6">
            <div>
              <h2 className="mb-0 text-md font-semibold text-gray-900">
                Brand Name
              </h2>
              <p className="text-xs text-gray-500">
                Enter your brand name, this goes in the top section of the chat
                interface
              </p>
              <input
                type="text"
                id="brand-name"
                value={companyName}
                onChange={handleCompanyChanged}
                className="mt-1 block w-full border-gray-300 rounded-md border p-2 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                placeholder=""
              />
            </div>

            <div className="pt-5">
              <BrandLogoUpload
                title="Brand Logo"
                subtitle="This goes into the top header section of the chatbot"
                logo={companyImageUrl}
                uploadHandler={uploadCompanyFile}
              />

              {/* <div className="col-span-full flex items-center gap-x-8">
                <img
                  alt="Company picture"
                  title="Company picture"
                  src={companyImageUrl}
                  className="h-24 w-24 flex-none rounded-lg bg-gray-200 object-cover"
                />
                <div>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    className="hidden"
                    id="company-picture"
                  />
                  <label
                    htmlFor="company-picture"
                    className="rounded-md bg-gray-100 px-3 py-2 text-sm font-semibold text-gray-700 shadow-sm hover:bg-gray-200 cursor-pointer"
                  >
                    Change company picture
                  </label>
                  <p className="mt-2 text-xs leading-5 text-gray-500">
                    JPG, GIF, or PNG. 1MB max.
                  </p>
                </div>
              </div>

              {uploadProgress > 0 && (
                <div className="sm:col-span-6">
                  <div className="mt-4">
                    <div className="w-full bg-gray-200 rounded-full h-2">
                      <div
                        className="bg-blue-600 h-2 rounded-full"
                        style={{ width: `${uploadProgress}%` }}
                      />
                    </div>
                    <p className="text-sm text-gray-700">{`Uploading: ${uploadProgress}%`}</p>
                  </div>
                </div>
              )}

              {uploadFeedback.status && (
                <div className="sm:col-span-6">
                  <div
                    className={`flex items-center p-4 rounded-lg ${uploadFeedback.status === "success"
                        ? "text-green-800 bg-green-50 dark:bg-gray-800 dark:text-green-400"
                        : "text-red-800 bg-red-50 dark:bg-gray-800 dark:text-red-400"
                      }`}
                    role="alert"
                  >
                    <svg
                      className="flex-shrink-0 w-4 h-4"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      {uploadFeedback.status === "success" ? (
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                      ) : (
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                        />
                      )}
                    </svg>
                    <span className="sr-only">Info</span>
                    <div className="ms-3 text-sm font-medium">
                      {uploadFeedback["msg"]}
                    </div>
                    <button
                      onClick={() => setUploadFeedback({})}
                      className={`ms-auto -mx-1.5 -my-1.5 rounded-lg focus:ring-2 p-1.5 inline-flex items-center justify-center h-8 w-8 dark:bg-gray-800 ${feedback["status"] === "success" ? "bg-green-50 text-green-500 hover:bg-green-200 focus:ring-green-400" : "bg-red-50 text-red-500 hover:bg-red-200 focus:ring-red-400"}`}
                      aria-label="Close"
                    >
                      <span className="sr-only">Close</span>
                      <svg
                        className="w-3 h-3"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 14 14"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              )} */}
            </div>

            <div className="pt-5">
              <h2 className="mb-0 text-md font-semibold text-gray-900">
                AI Agent Name
              </h2>
              <p className="text-xs text-gray-500">
                Name your new AI agent so they can have a personality. Or you
                can simply choose to name them after your business.
              </p>
              <input
                type="text"
                id="ai-employee-name"
                value={aiEmployeeName}
                onChange={handleAiEmployeeNameChanged}
                className="mt-1 block w-full border-gray-300 rounded-md border p-2 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                placeholder="Molly"
              />
            </div>

            <div className="pt-5">
              <BrandLogoUpload
                title="AI Agent Profile Photo"
                logo={chatbotPictureUrl}
                uploadHandler={uploadChatbotFile}
              />
            </div>

            <div>
              <div className="mb-4">
                <h2 className="text-md font-medium text-gray-900">
                  Chat Icon Color
                </h2>
              </div>

              <ColorPicker
                color={chatButtonColor}
                setColor={handleChatButtonColorChanged}
              />
            </div>

            <div className="pt-5">
              <div className="mb-4">
                <h2 className="text-md font-medium text-gray-900">
                  Chat Send Button & Message Color
                </h2>
              </div>

              <ColorPicker
                color={buttonColor}
                setColor={handleButtonColorChanged}
              />
            </div>

            <div className="pt-5">
              <h2 className="mb-0 text-md font-semibold text-gray-900">
                AI Agent Instructions
              </h2>
              <label className="block text-sm text-gray-500">
                This helps your AI agent better understand how you want them to
                respond to customers to be in alignment with your brand tone.
              </label>

              <textarea
                id="brand-voice"
                rows="6"
                value={prompts}
                onChange={handleBrandVoiceChanged}
                className="w-full mt-1 block border-gray-300 rounded-lg border p-2 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                placeholder="Enter a message..."
              />

              {/* <div className="mt-2 flex gap-4">
                <OptionSelect options={brandVoiceOptions} />
              </div> */}
            </div>

            {/* <div className="pt-5">
              <h2 className="mb-0 text-md font-semibold text-gray-900">
                Answer Length
              </h2>
              <label className="block text-sm text-gray-500">
                This helps your AI agent better understand how you want them
                to respond to customers to be in alignment with your brand tone.
              </label>
              <div className="mt-2 flex gap-4">
                <OptionSelect options={answerLengthOptions} />
              </div>
            </div> */}

            <div className="pt-5m">
              <h2 className="mb-0 text-md font-semibold text-gray-900">
                Intro message
              </h2>
              <label className="block text-sm text-gray-500">
                When customers open your live chat, this is the first message
                they will see.
              </label>

              <textarea
                id="intro-message"
                rows="6"
                value={greeting}
                onChange={handleIntroMesageChanged}
                className="w-full mt-1 block border-gray-300 rounded-lg border p-2 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                placeholder="Enter a message..."
              />
            </div>

            {/* <div className="pt-5">
              <h2 className="mb-0 text-lg font-semibold text-gray-900">
                Brand Background
              </h2>
              <label className="block text-sm text-gray-500">
                This will be used by your AI agent to better understand your
                business from your perspective and help them act like one of
                your best customer support team members.
              </label>

              <label htmlFor="brand-background" className="mt-5 block text-sm text-gray-500">
                Description *
              </label>

              <textarea
                id="brand-background"
                rows="6"
                className="w-full mt-1 block text-black border-gray-300 rounded-lg border p-2 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                placeholder="Enter your brand background here..."
              />

              <span className="text-sm text-gray-500">
                This is a hint text to help the user
              </span>
            </div> */}
          </div>
        </div>

        <div className="w-3/4 mx-auto mt-5 3xl:w-1/2 p-0 flex items-start justify-center min-w-1/2">
          <div className="w-full relative">
            <iframe
              id="support-magic-widget-iframe"
              src={`${WIDGET_URL}/chat.html`}
              title="Live Chat Widget"
              style={{
                border: "none",
                top: 0,
                width: "90%",
                marginLeft: "5%",
                minHeight: "900px",
                position: "relative",
                borderRadius: "5px",
                zIndex: 9999,
              }}
            />

            <div id="support-magic-chat-launcher" style={launcherStyle}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                width="32"
                height="32"
                stroke-width="1"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M19 14l-7-7-7 7"
                ></path>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </CenterWrapper>
  );
};

export default Branding;
