import React, { useState, useEffect } from "react";
import { ReactComponent as TitleIcon } from "../../imgs/icons/userPlus.svg";
import { ReactComponent as CloseButton } from "../../imgs/icons/buttons/close.svg";

const EditTeamMemberModal = ({ isOpen, onClose, editUserData, onSuccess, setLoading, fetchTeam, api}) => {
  const [updatedUser, setUpdatedUser] = useState({});

  useEffect(() => {
    setUpdatedUser(editUserData);
  }, [editUserData]);

  const handleRoleChange = (e) => {
    setUpdatedUser((prev) => ({ ...prev, role: e.target.value }));
  };

  const handleEditSaveUser = async () => {
    setLoading(true);
    try {
      const userObj = {
        role: updatedUser.role,
      };

      await api.put(`/api/team/user/${updatedUser.id}`, userObj);

      await fetchTeam();
      onSuccess();
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-lg overflow-y-auto max-h-screen">
        <div className="flex justify-between items-center">
          <TitleIcon width={60} height={60} className="p-4 ml-6 mt-6 border rounded-xl" />
          <CloseButton className="mr-6 cursor-pointer" onClick={onClose} />
        </div>
        <div className="flex justify-between items-center mx-6 pt-3">
          <h2 className="text-lg font-semibold text-gray-800">Manage team member</h2>
        </div>

        <div className="border-t">
          <div className="mb-4 mx-6">
            <div className="mt-4 space-y-4">
              <div className="flex items-center space-x-2">
                {/* Email Input */}
                <div className="flex-1">
                  <label className="text-sm text-gray-600">Email address</label>
                  <input
                    type="email"
                    value={updatedUser?.email || ""}
                    className="w-full px-3 py-2 border rounded-lg focus:ring focus:ring-indigo-200"
                    disabled
                  />
                </div>

                {/* Role Dropdown */}
                <div className="w-28">
                  <label className="text-sm text-gray-600">Role</label>
                  <select
                    className="w-full px-3 py-2 border rounded-lg bg-white focus:ring focus:ring-indigo-200"
                    value={updatedUser?.role || ""}
                    onChange={handleRoleChange}
                  >
                    <option value="admin">Admin</option>
                    <option value="member">Member</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-center py-3 border-t">
          <button
            onClick={onClose}
            className="w-1/2 text-md font-bold text-gray-500 hover:bg-gray-100 border shadow-md ml-6 mr-1 py-3 rounded-md"
          >
            Cancel
          </button>
          <button
            onClick={handleEditSaveUser}
            className="w-1/2 text-md bg-secondary border border-primary shadow-md hover:opacity-75 font-bold text-white ml-1 mr-6 py-3 rounded-md"
          >
            Send invites
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditTeamMemberModal;
